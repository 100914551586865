import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-background text-text p-8">
      <h1 className="text-3xl font-bold mb-4 text-primary">Privacy Policy</h1>

      <p className="mb-4">
        SG Gold ("we", "our", or "us") is committed to protecting the privacy of
        its users. This Privacy Policy describes how we collect, use, and share
        personal information when you use our mobile application ("App")
        developed as part of our jewelry management services. By using the App,
        you consent to the collection and use of your personal information as
        described in this policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        1. Information We Collect
      </h2>
      <p className="mb-4">
        We collect various types of information to provide and improve our
        services:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <strong>Personal Information:</strong> When you register or interact
          with our App, we may collect personal details such as your name, email
          address, phone number, PAN, address, and other necessary details for
          account management and order processing.
        </li>
        <li>
          <strong>Usage Data:</strong> We collect information about your
          interactions with the App, including your IP address, browser type,
          device identifiers, and usage patterns such as pages viewed, time
          spent on the App, and the actions you take.
        </li>
        <li>
          <strong>Order and Payment Information:</strong> When you place orders,
          we collect details about the products you purchase, payment methods,
          and transaction details. We do not store payment card details;
          payments are processed through secure third-party services.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        2. How We Use Your Information
      </h2>
      <p className="mb-4">We use the collected information to:</p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          Provide and maintain the App's features and services, such as managing
          products, orders, and account settings.
        </li>
        <li>Process orders, payments, and customer support requests.</li>
        <li>
          Send notifications regarding your orders (e.g., status updates,
          confirmations, etc.).
        </li>
        <li>
          Improve the functionality, security, and user experience of the App.
        </li>
        <li>
          Comply with legal obligations and protect our rights and interests.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        3. How We Share Your Information
      </h2>
      <p className="mb-4">
        We do not share your personal information with third parties except in
        the following cases:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <strong>Service Providers:</strong> We may share information with
          third-party service providers (such as payment processors or hosting
          services) that help us provide and maintain the App.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information
          when required by law or in response to a legal process.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger,
          acquisition, or asset sale, your information may be transferred as
          part of the transaction.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        4. Data Security
      </h2>
      <p className="mb-4">
        We implement appropriate technical and organizational measures to
        protect your personal information against unauthorized access, loss,
        misuse, or disclosure. However, please be aware that no method of
        transmitting data over the Internet or storing data is completely
        secure.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        5. Your Rights
      </h2>
      <p className="mb-4">
        You have the following rights regarding your personal data:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <strong>Access and Correction:</strong> You may request access to or
          correction of your personal information.
        </li>
        <li>
          <strong>Data Deletion:</strong> You may request that we delete your
          personal information unless we are required to retain it by law or for
          legitimate business purposes.
        </li>
        <li>
          <strong>Opt-Out:</strong> You may opt-out of receiving marketing
          communications from us by following the unsubscribe instructions in
          the communications or contacting us directly.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        6. Third-Party Links
      </h2>
      <p className="mb-4">
        The App may contain links to third-party websites or services. We are
        not responsible for the privacy practices of these third parties, and we
        encourage you to review their privacy policies before providing any
        personal information.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        7. Changes to This Privacy Policy
      </h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Any changes will be
        effective when we post the updated Privacy Policy within the App. We
        encourage you to review this policy periodically to stay informed about
        how we protect your information.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        8. Contact Information
      </h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy or our data
        practices, please contact us at:
      </p>
      <p className="mb-4">
        <strong>Sujain Girish Jain (SG Gold)</strong>
        <br />
        Address: 302, 3rd Floor, Madhuban, Muthaliya Residency, Dattaram Lad
        Marg, Mumbai 400033
        <br />
        Email:{" "}
        <a href="mailto:swarnashahibysggold@gmail.com" className="text-primary">
          swarnashahibysggold@gmail.com
        </a>
      </p>

      <p className="mt-8 text-sm text-neutral-light">
        By using the App, you acknowledge that you have read, understood, and
        agreed to the terms of this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
