import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "../../services/orderService";

export const getAllOrdersForAdminThunk = createAsyncThunk(
  "orders/getAllOrdersForAdmin",
  async (filters, { rejectWithValue }) => {
    try {
      const response = await orderService.getAllOrdersForAdmin(filters);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateMultipleOrderStatusByAdminThunk = createAsyncThunk(
  "orders/updateMultipleOrderStatusByAdmin",
  async (orderData, { rejectWithValue }) => {
    try {
      const response =
        await orderService.updateMultipleOrderStatusByAdmin(orderData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteMultipleOrdersByAdminThunk = createAsyncThunk(
  "orders/deleteMultipleOrdersByAdmin",
  async (orderIds, { rejectWithValue }) => {
    try {
      const response = await orderService.deleteMultipleOrdersByAdmin(orderIds);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const clearOrderError = createAction("orders/clearOrderError");
