import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _debounce from "lodash/debounce";
import {
  getAllProductsThunk,
  deleteProductByIdThunk,
  updateProductStatusByIdThunk,
  clearAdminProductError,
} from "../../store/thunks/adminProductThunks";
import Button from "../../components/common/Button";
import Filter from "../../components/common/Filter";
import Checkbox from "../../components/common/Checkbox";
import { notifyError } from "../../utils/notification";
import ProductCard from "./components/ProductCard";
import Modal from "../../components/common/Modal";
import AdminProductEditCreateModal from "./components/AdminProductEditCreateModal";
import { getFormattedDate } from "../../utils/helpers";
import { exportToExcel } from "../../utils/exportHelpers";
import { getCategoriesWithChild } from "../../utils/productHelpers";

const productFilterStatus = {
  All: "",
  Active: "ACTIVE",
  Inactive: "INACTIVE",
};

const productAction = {
  activate: "ACTIVE",
  deactivate: "INACTIVE",
  delete: "delete",
};

const buttonsConfig = {
  Active: [
    { variant: "danger", action: "deactivate", label: "Deactivate" },
    { variant: "primary", action: "exportToExcel", label: "Export to Excel" },
  ],
  Inactive: [
    { variant: "success", action: "activate", label: "Activate" },
    { variant: "primary", action: "exportToExcel", label: "Export to Excel" },
  ],
  default: [
    { variant: "success", action: "activate", label: "Activate" },
    { variant: "danger", action: "deactivate", label: "Deactivate" },
    { variant: "primary", action: "exportToExcel", label: "Export to Excel" },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
};

const AdminProductPage = () => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector(
    (state) => state.adminProduct
  );
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("Active");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearAdminProductError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(getAllProductsThunk({ status: productFilterStatus.Active }));
  }, [dispatch]);

  useEffect(() => {
    setSelectAll(
      selectedProducts.length === products.length && products.length > 0
    );
  }, [selectedProducts, products]);

  const debouncedSearch = useCallback(
    _debounce((term) => {
      dispatch(
        getAllProductsThunk({
          status: productFilterStatus[currentFilter],
          search: term,
        })
      );
    }, 300),
    [dispatch, currentFilter]
  );

  const handleFilterChange = useCallback(
    (status) => {
      setCurrentFilter(status);
      dispatch(
        getAllProductsThunk({
          status: productFilterStatus[status],
          search: searchTerm,
        })
      );
      setSelectedProducts([]);
      setSelectAll(false);
    },
    [dispatch, searchTerm]
  );

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const handleSelectProduct = useCallback((productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  }, []);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map((product) => product._id));
    }
    setSelectAll(!selectAll);
  }, [selectAll, products]);

  const handleBulkAction = useCallback(
    (action) => {
      if (action === "exportToExcel") {
        handleExportToExcel();
        return;
      }

      const actionConfirmed =
        action === "delete"
          ? window.confirm("Are you sure you want to delete these products?")
          : true;

      if (actionConfirmed) {
        const actionThunk =
          action === "delete"
            ? deleteProductByIdThunk
            : updateProductStatusByIdThunk;

        const actionPayload =
          action === "delete"
            ? selectedProducts
            : selectedProducts.map((productId) => ({
                id: productId,
                status: productAction[action],
              }));

        Promise.all(
          actionPayload.map((payload) => dispatch(actionThunk(payload)))
        ).then(() => {
          dispatch(
            getAllProductsThunk({
              status: productFilterStatus[currentFilter],
              search: searchTerm,
            })
          );
        });
      }
    },
    [selectedProducts, dispatch, currentFilter, searchTerm]
  );

  const handleSingleAction = useCallback(
    (productId, action) => {
      const actionConfirmed =
        action === "delete"
          ? window.confirm("Are you sure you want to delete this product?")
          : true;

      if (actionConfirmed) {
        const actionThunk =
          action === "delete"
            ? deleteProductByIdThunk
            : updateProductStatusByIdThunk;

        const actionPayload =
          action === "delete"
            ? { id: productId }
            : { id: productId, status: productAction[action] };

        dispatch(actionThunk(actionPayload)).then(() => {
          dispatch(
            getAllProductsThunk({
              status: productFilterStatus[currentFilter],
              search: searchTerm,
            })
          );
        });
      }
    },
    [dispatch, currentFilter, searchTerm]
  );

  const renderBulkActionButtons = useCallback(() => {
    return (buttonsConfig[currentFilter] || buttonsConfig.default).map(
      ({ variant, action, label }) => (
        <Button
          key={action}
          variant={variant}
          onClick={() => handleBulkAction(action)}
          disabled={!selectedProducts.length}
        >
          {label}
        </Button>
      )
    );
  }, [currentFilter, handleBulkAction, selectedProducts.length]);

  // New function to handle export to Excel
  const handleExportToExcel = () => {
    const selectedProductData = products
      .filter((product) => selectedProducts.includes(product._id))
      .map(
        ({
          __v,
          _id,
          media,
          categories,
          createdAt,
          updatedAt,
          productId,
          tags,
          stoneWeight,
          stoneCharges,
          isStone,
          gender,
          ...rest
        }) => ({
          "Product Id": productId,
          "Created At": getFormattedDate(createdAt),
          categories: getCategoriesWithChild(categories),
          tags: tags.join(", "),
          ...rest,
          "Is Stone": !isStone ? "No" : "Yes",
          "Stone Weight": isStone ? stoneWeight : "", // Ensure empty string if isStone is false
          "Stone Charges": isStone ? stoneCharges : "", // Ensure empty string if isStone is false
        })
      );

    const keyValueData = [
      { key: "Exported", value: new Date().toLocaleString() },
    ];

    exportToExcel(keyValueData, selectedProductData, "Products");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);

  const openModal = useCallback((productId) => {
    setEditingProductId(productId);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback((noConfirmation) => {
    if (noConfirmation) {
      // this is called when update or create is successful
      setEditingProductId(null);
      setIsModalOpen(false);
      dispatch(
        getAllProductsThunk({
          status: productFilterStatus[currentFilter],
          search: searchTerm,
        })
      );
      return;
    }

    const confirmed = window.confirm(
      "Are you sure you want to close the modal?"
    );

    if (confirmed) {
      setEditingProductId(null);
      setIsModalOpen(false);
    }
  }, []);

  const renderModal = useMemo(() => {
    return (
      <Modal
        isOpen={isModalOpen}
        closeModal={() => closeModal()}
        title={editingProductId ? "Edit Product" : "Add New Product"}
        className="p-8"
        isFullScreen
      >
        <AdminProductEditCreateModal
          id={editingProductId}
          closeModal={closeModal}
        />
      </Modal>
    );
  }, [editingProductId, isModalOpen]);

  return (
    <>
      <div className="container mx-auto p-4 bg-background text-text">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Products</h1>

          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 border rounded"
          />
        </div>
        <div className="sticky top-0 z-10 bg-background py-2 mb-4">
          <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
            <Filter
              defaultOption="Active"
              options={["All", "Active", "Inactive"]}
              onSelect={handleFilterChange}
            />
            <div className="flex gap-2 items-center justify-center mt-4 lg:mt-0">
              <div>{`Selected: ${selectedProducts.length}`}</div>
              <Checkbox
                label=""
                name="select-all"
                checked={selectAll}
                onChange={handleSelectAll}
                size="lg"
                className="mx-2 mr-4"
              />
              {renderBulkActionButtons()}
              <div className="mx-2 h-6 border-r border-gray-300"></div>
              <Button variant="primary" onClick={() => openModal(null)}>
                Create Product
              </Button>
            </div>
          </div>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {products.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                handleSelectProduct={handleSelectProduct}
                handleSingleAction={handleSingleAction}
                selected={selectedProducts.includes(product._id)}
                currentFilter={currentFilter}
                onEdit={() => openModal(product._id)}
              />
            ))}
          </div>
        )}
        {!loading && products.length <= 0 && (
          <p>No Products Found For {currentFilter}</p>
        )}
        {renderModal}
      </div>
    </>
  );
};

export default AdminProductPage;
