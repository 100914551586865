import api from "./api";

export const fetchMedia = async (fileUrl) => {
  try {
    // Check if the file URL matches the pattern https://pub-[SOME RANDOM ID].r2.dev
    const regex = /^https:\/\/pub-[\w-]+\.r2\.dev\/.+$/;
    if (regex.test(fileUrl)) {
      return fileUrl;
    }

    // If it doesn't match, proceed with fetching the signed URL
    const response = await api.post("/get-signed-url", { fileUrl });
    return response.data.url;
  } catch (error) {
    console.error("Error fetching signed URL:", error);
  }
};
