import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";

const Dropdown = ({
  label,
  options,
  selected,
  onChange,
  error,
  isOptionsLoading,
  multiple,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = useCallback(() => {
    setDropdownOpen((prev) => !prev);
  }, []);

  const handleOptionClick = useCallback(
    (option) => {
      if (multiple) {
        const newValue = selected.some((item) => item._id === option._id)
          ? selected.filter((item) => item._id !== option._id)
          : [...selected, option];
        onChange(newValue);
        setDropdownOpen(false);
      } else {
        onChange(option);
      }
    },
    [multiple, onChange, selected]
  );

  const isSelected = useCallback(
    (option) => {
      return multiple
        ? selected.some((item) => item._id === option._id)
        : selected?._id === option._id;
    },
    [multiple, selected]
  );

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.name.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, options]
  );

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="relative mb-4" ref={dropdownRef}>
      <label className="block text-sm font-medium text-text">{label}</label>
      <div
        className="relative w-full bg-background border border-neutral-light rounded-md shadow-sm cursor-pointer"
        onClick={toggleDropdown}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={dropdownOpen}
      >
        <div className="flex justify-between items-center px-3 py-2">
          <span className="block truncate text-text">
            {multiple
              ? selected.map((item) => item.name).join(", ")
              : selected?.name || "Select an option"}
          </span>
          <span className="pointer-events-none">
            <svg
              className={`h-5 w-5 transition-transform ${
                dropdownOpen ? "transform rotate-180" : ""
              } text-text`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        {dropdownOpen && (
          <div className="absolute mt-1 w-full rounded-md bg-background shadow-lg z-10">
            <div className="py-1">
              <input
                type="text"
                className="block w-full px-3 py-2 border border-neutral-light rounded-md focus:outline-none focus:ring-primary focus:border-primary"
                placeholder="Search..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                aria-label="Search options"
              />
            </div>
            <ul
              className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              role="listbox"
            >
              {isOptionsLoading ? (
                <li className="px-4 py-2 text-text text-center">Loading...</li>
              ) : (
                filteredOptions.map((option) => (
                  <li
                    key={option._id}
                    className={`cursor-pointer select-none relative py-2 pl-3 pr-9 ${
                      isSelected(option) ? "text-white bg-primary" : "text-text"
                    }`}
                    onClick={() => handleOptionClick(option)}
                    role="option"
                    aria-selected={isSelected(option)}
                  >
                    <span
                      className={`block truncate ${
                        isSelected(option) ? "font-semibold" : "font-normal"
                      }`}
                    >
                      {option.name}
                    </span>
                    {isSelected(option) && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                        <svg
                          className="h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </li>
                ))
              )}
            </ul>
          </div>
        )}
      </div>
      {error && <div className="text-danger text-sm mt-1">{error}</div>}
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  isOptionsLoading: PropTypes.bool,
  multiple: PropTypes.bool,
};

Dropdown.defaultProps = {
  error: "",
  isOptionsLoading: false,
  multiple: false,
};

export default React.memo(Dropdown);
