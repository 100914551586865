import React from "react";

const InfoSection = ({
  info,
  title,
  className = "",
  itemClassName = "",
  labelClassName = "",
  valueClassName = "",
}) => (
  <>
    {title && <h3 className="text-lg font-semibold my-2">{title}</h3>}
    <div className={`pr-0 lg:pr-4 flex flex-col ${className}`}>
      {info.map(({ label, value }) => (
        <>
          {value != undefined && (
            <p
              key={label}
              className={`text-sm text-neutral-dark ${itemClassName} `}
            >
              <span className={labelClassName}>{label}:</span>{" "}
              <span className={`text-primary-light  ${valueClassName}  `}>
                {value}
              </span>
            </p>
          )}
        </>
      ))}
    </div>
  </>
);

export default InfoSection;
