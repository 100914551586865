import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersForAdminThunk,
  approveRejectMultipleUsersThunk,
  blockUnblockMultipleUsersThunk,
  deleteMultipleUsersThunk,
  clearUserError,
} from "../../store/thunks/userThunks";
import Button from "../../components/common/Button";
import Filter from "../../components/common/Filter";
import Checkbox from "../../components/common/Checkbox";
import { notifyError } from "../../utils/notification";
import UserCard from "./components/UserCard";
import UserActionModal from "./components/UserActionModal";

const userFilterStatus = {
  Accepted: "accepted",
  Rejected: "rejected",
  Blocked: "blocked",
  Pending: "pending",
  DeleteRequested: "pending-deletion",
  Unverified: "",
  All: "",
};

const editAction = {
  rejected: "reject",
  blocked: "block",
};

const buttonsConfig = {
  Pending: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
  ],
  Rejected: [{ variant: "success", action: "accept", label: "Accept" }],
  Blocked: [{ variant: "success", action: "unblock", label: "Unblock" }],
  Accepted: [{ variant: "danger-outline", action: "block", label: "Block" }],
  DeleteRequested: [
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
  Unverified: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
  ],
  default: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
    { variant: "danger-outline", action: "block", label: "Block" },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
};

const UsersPage = () => {
  const dispatch = useDispatch();
  const { users, loading, error } = useSelector((state) => state.user);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("Pending");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalUserIds, setModalUserIds] = useState([]);
  const [modalAllowUpdate, setModalAllowUpdate] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearUserError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(
      getUsersForAdminThunk({
        status: userFilterStatus.Pending,
        includeUnverified: currentFilter === "Unverified",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (selectedUsers.length === users.length && users.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedUsers, users]);

  const handleFilterChange = (status) => {
    setCurrentFilter(status);
    dispatch(
      getUsersForAdminThunk({
        status: userFilterStatus[status],
        includeUnverified: status === "Unverified",
      })
    );
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user._id));
    }
    setSelectAll(!selectAll);
  };

  const openModal = (action, userIds, allowUpdate = false) => {
    setModalAction(action);
    setModalUserIds(userIds);
    setModalAllowUpdate(allowUpdate);
    setIsModalOpen(true);
  };

  const handleBulkAction = (status) => {
    const actionConfirmed =
      status === "delete"
        ? window.confirm("Are you sure you want to delete these users?")
        : true;

    if (actionConfirmed) {
      if (status === "block" || status === "reject") {
        openModal(status, selectedUsers, status === "reject");
        return;
      }

      const actionThunk =
        status === "delete"
          ? deleteMultipleUsersThunk
          : status === "unblock"
            ? blockUnblockMultipleUsersThunk
            : approveRejectMultipleUsersThunk;

      const actionPayload =
        status === "delete"
          ? selectedUsers
          : {
              userIds: selectedUsers,
              approve: status === "accept",
              block: status === "block",
              message: "",
              allowUpdate: status !== "block",
            };

      dispatch(actionThunk(actionPayload)).then(() => {
        dispatch(
          getUsersForAdminThunk({
            status: userFilterStatus[currentFilter],
            includeUnverified: currentFilter === "Unverified",
          })
        );
      });
    }
  };

  const handleSingleAction = (userId, action) => {
    const actionConfirmed =
      action === "delete"
        ? window.confirm("Are you sure you want to delete this user?")
        : true;

    if (actionConfirmed) {
      if (action === "block" || action === "reject") {
        openModal(action, [userId], action === "reject");
        return;
      }

      const actionThunk =
        action === "delete"
          ? deleteMultipleUsersThunk
          : action === "unblock"
            ? blockUnblockMultipleUsersThunk
            : approveRejectMultipleUsersThunk;

      const actionPayload =
        action === "delete"
          ? [userId]
          : {
              userIds: [userId],
              approve: action === "accept",
              block: action === "block",
              message: "",
              allowUpdate: action !== "block",
            };

      dispatch(actionThunk(actionPayload)).then(() => {
        dispatch(
          getUsersForAdminThunk({
            status: userFilterStatus[currentFilter],
            includeUnverified: currentFilter === "Unverified",
          })
        );
      });
    }
  };

  const handleEditAdminMessage = (user) => {
    setEditingUser(user);
    openModal("editMessage", [user._id]);
  };

  const renderBulkActionButtons = () => {
    return (buttonsConfig[currentFilter] || buttonsConfig.default).map(
      ({ variant, action, label }) => (
        <Button
          key={action}
          variant={variant}
          onClick={() => handleBulkAction(action)}
          disabled={!selectedUsers.length}
        >
          {label}
        </Button>
      )
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalAction("");
    setModalUserIds([]);
    setModalAllowUpdate(false);
    setEditingUser(null);
  };

  const handleModalSubmit = (message, allowUpdate) => {
    const actionStatus = editAction[editingUser?.status] || modalAction;
    const actionThunk =
      actionStatus === "block" ||
      actionStatus === "unblock" ||
      actionStatus === "editMessage"
        ? blockUnblockMultipleUsersThunk
        : approveRejectMultipleUsersThunk;

    const actionPayload = {
      userIds: modalUserIds,
      approve: actionStatus === "accept",
      block: actionStatus === "block",
      message,
      allowUpdate,
    };

    dispatch(actionThunk(actionPayload)).then(() => {
      dispatch(
        getUsersForAdminThunk({
          status: userFilterStatus[currentFilter],
          includeUnverified: currentFilter === "Unverified",
        })
      );
      closeModal();
    });
  };

  return (
    <div className="container mx-auto p-4 bg-background text-text">
      <h1 className="text-2xl font-bold mb-4">Users</h1>
      <div className="sticky top-0 z-10 bg-background py-2 mb-4">
        <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
          <Filter
            defaultOption="Pending"
            options={[
              "All",
              "Pending",
              "Accepted",
              "Rejected",
              "Blocked",
              "DeleteRequested",
              "Unverified",
            ]}
            onSelect={handleFilterChange}
            maxTabs={3}
          />
          <div className="flex gap-2 items-center justify-center mt-4 lg:mt-0">
            <div>{`Selected: ${selectedUsers.length}`}</div>
            <Checkbox
              label=""
              name="select-all"
              checked={selectAll}
              onChange={handleSelectAll}
              size="lg"
              className="mx-2 mr-4"
            />
            {renderBulkActionButtons()}
          </div>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {users.map((user) => (
            <UserCard
              key={user._id}
              user={user}
              handleSelectUser={handleSelectUser}
              handleSingleAction={handleSingleAction}
              selected={selectedUsers.includes(user._id)}
              currentFilter={currentFilter}
              onEditAdminMessage={handleEditAdminMessage}
            />
          ))}
        </div>
      )}
      {!loading && users.length <= 0 && (
        <p>No Users Found For {currentFilter}</p>
      )}
      <UserActionModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        action={modalAction}
        userIds={modalUserIds}
        allowUpdate={modalAllowUpdate}
        onSubmit={handleModalSubmit}
        editingUser={editingUser}
      />
    </div>
  );
};

export default UsersPage;
