// src/store/slices/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  requestPasswordReset,
  clearAuthError,
  clearPasswordResetMessage,
  logout,
  getMyDetailsThunk,
} from "../thunks/authThunks";

const initialState = {
  user: null,
  isRootAdmin: false,
  token: localStorage.getItem("token"),
  refreshToken: localStorage.getItem("refreshToken"),
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.admin;
        state.isRootAdmin = action.payload.admin.role === "root";
        state.token = action.payload.authToken;
        state.refreshToken = action.payload.refreshToken;
        localStorage.setItem("token", action.payload.authToken);
        localStorage.setItem("refreshToken", action.payload.refreshToken);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
      })
      .addCase(getMyDetailsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.isRootAdmin = action.payload.role === "root";
        state.user = action.payload;
      })
      .addCase(getMyDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || action.payload;
      })
      .addCase(requestPasswordReset.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(requestPasswordReset.fulfilled, (state, action) => {
        state.loading = false;
        state.passwordResetMessage = action.payload.displayMessage;
      })
      .addCase(requestPasswordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(clearAuthError, (state) => {
        state.error = null;
      })
      .addCase(clearPasswordResetMessage, (state) => {
        state.passwordResetMessage = null;
      });
  },
});

export default authSlice.reducer;
