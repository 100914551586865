// src/store/thunks/userThunks.js
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/userService";

export const getUsersForAdminThunk = createAsyncThunk(
  "user/getUsersForAdmin",
  async (queryParams, { rejectWithValue }) => {
    try {
      const response = await userService.getUsersForAdmin(queryParams);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const approveRejectMultipleUsersThunk = createAsyncThunk(
  "user/approveRejectMultipleUsers",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await userService.approveRejectMultipleUsers(userData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const blockUnblockMultipleUsersThunk = createAsyncThunk(
  "user/blockUnblockMultipleUsers",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await userService.blockUnblockMultipleUsers(userData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteMultipleUsersThunk = createAsyncThunk(
  "user/deleteMultipleUsers",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await userService.deleteMultipleUsers(userData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const requestAccountDeleteThunk = createAsyncThunk(
  "user/requestAccountDelete",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await userService.requestAccountDelete(userData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;

      console.log(error)
      return rejectWithValue(errorMessage);
    }
  }
);

export const clearUserError = createAction("user/clearUserError");
