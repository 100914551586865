import {
  HomeIcon,
  ShoppingCartIcon,
  TagIcon,
  ArchiveBoxIcon,
  UserIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline"; // Import your icon components
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import Layout from "../components/layout/HorizontalLayout";
import ProtectedRoute from "../components/common/ProtectedRoute";
import Orders from "../pages/NewOrders";
import CategoryPage from "../pages/Category";
import AdminProductPage from "../pages/AdminProduct/AdminProductPage";
import UsersPage from "../pages/User";
import AdminPage from "../pages/Admin"; // Import Admin Management page
import PrivacyPolicy from "../pages/PrivacyPolicy";
import RequestAccountDeletePage from "../pages/RequestAccountDelete"; // Import the new page

const ROUTE_PATHS = {
  LOGIN: "/login",
  HOME: "/",
  ORDERS: "/orders",
  CATEGORY: "/category",
  PRODUCT: "/products",
  USER: "/user",
  ADMIN: "/admin",
  NOT_FOUND: "*",
  PRIVACY_POLICY: "/public/privacy-policy",
  REQUEST_ACCOUNT_DELETE: "public/request-delete",
};

const routes = [
  {
    path: ROUTE_PATHS.LOGIN,
    element: <Login />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <Layout />,
        children: [
          { path: ROUTE_PATHS.HOME, element: <Orders /> },
          { path: ROUTE_PATHS.ORDERS, element: <Orders /> },
          { path: ROUTE_PATHS.USER, element: <UsersPage /> },
          { path: ROUTE_PATHS.CATEGORY, element: <CategoryPage /> },
          { path: ROUTE_PATHS.PRODUCT, element: <AdminProductPage /> },
          { path: ROUTE_PATHS.ADMIN, element: <AdminPage /> },
        ],
      },
    ],
  },
  {
    path: ROUTE_PATHS.NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: ROUTE_PATHS.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTE_PATHS.REQUEST_ACCOUNT_DELETE,
    element: <RequestAccountDeletePage />,
  },
];

const menuRoutes = [
  // { path: ROUTE_PATHS.HOME, name: "Dashboard", icon: HomeIcon },
  { path: ROUTE_PATHS.ORDERS, name: "Orders", icon: ShoppingCartIcon },
  { path: ROUTE_PATHS.PRODUCT, name: "Product", icon: ArchiveBoxIcon },
  { path: ROUTE_PATHS.CATEGORY, name: "Category", icon: TagIcon },
  { path: ROUTE_PATHS.USER, name: "Users", icon: UserIcon },
  { path: ROUTE_PATHS.ADMIN, name: "Admins", icon: ShieldCheckIcon },
];

export { routes, ROUTE_PATHS, menuRoutes };
