// src/store/configureStore.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

let store;

export const configureAppStore = () => {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
  });
  return store;
};

export const getStore = () => store;
