import React, { useState } from "react";
import Button from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import Checkbox from "../../../components/common/Checkbox";

const AdminCreateModal = ({  onSubmit }) => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const validate = () => {
    let tempErrors = {};
    if (!formData.email) tempErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Email is not valid";
    if (!formData.name) tempErrors.name = "Name is required";
    // if (!formData.phoneNumber)
    //   tempErrors.phoneNumber = "Phone number is required";
    // if (!/^\d{10}$/.test(formData.phoneNumber))
    //   tempErrors.phoneNumber = "Phone number is not valid";
    if (!formData.password) tempErrors.password = "Password is required";
    if (formData.password.length < 6)
      tempErrors.password = "Must be at least 6 char long";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      copyCredentials();
      onSubmit(formData);
    }
  };

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setFormData({
      ...formData,
      password: randomPassword,
    });
  };

  const copyCredentials = () => {
    const credentials = `Email: ${formData.email}\nName: ${formData.name}\nPhone Number: ${formData.phoneNumber}\nPassword: ${formData.password}`;
    navigator.clipboard.writeText(credentials);
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <Input
        label="Email"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        error={errors.email}
        placeholder="Enter admin email"
      />
      <Input
        label="Name"
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        error={errors.name}
        placeholder="Enter admin name"
      />
      <Input
        label="Phone Number (Optional)"
        type="text"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        error={errors.phoneNumber}
        placeholder="Enter admin phone number"
      />
      <div className="flex space-x-4">
        <div className="flex-1">
          <Input
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password}
            placeholder="Enter admin password"
          />
        </div>
        <div className="mt-6">
          <Button variant="secondary" onClick={generatePassword}>
            Generate Password
          </Button>
        </div>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <Checkbox
          checked={showPassword}
          onChange={(e) => setShowPassword(e.target.checked)}
          className=""
        />
        <label htmlFor="show-password" className="text-sm font-medium">
          View Password
        </label>
      </div>
      <div className="flex justify-end space-x-2">
        <Button variant="primary" type="submit">
          Copy and Create
        </Button>
      </div>
    </form>
  );
};

export default AdminCreateModal;
