import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/common/Button";
import Checkbox from "../../../components/common/Checkbox";
import Badge from "../../../components/common/Badge";
import ListCard from "../../../components/UI/ListCard";
import InfoSection from "../../../components/UI/InfoSection";
import PreviewFile from "../../../components/common/PreviewFile";
import { fetchMedia } from "../../../services/mediaService"; // Import the fetchMedia helper
import { getFormattedDate } from "../../../utils/helpers";
import { getCategoriesWithChild } from "../../../utils/productHelpers";

const statusVariants = {
  ACTIVE: "success",
  INACTIVE: "danger",
};

const buttonsConfig = {
  ACTIVE: [{ variant: "danger", action: "deactivate", label: "Deactivate" }],
  INACTIVE: [{ variant: "success", action: "activate", label: "Activate" }],
  default: [
    { variant: "success", action: "activate", label: "Activate" },
    { variant: "danger", action: "deactivate", label: "Deactivate" },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
};

const ProductCard = ({
  product,
  handleSelectProduct,
  handleSingleAction,
  selected,
  currentFilter,
  onEdit,
}) => {
  const { updatingCards } = useSelector((state) => state.adminProduct);
  const loading = updatingCards.includes(product._id);

  const [mediaUrls, setMediaUrls] = useState([]);
  const mediaCache = useMemo(() => new Map(), []);

  useEffect(() => {
    const loadMediaUrls = async () => {
      const urls = await Promise.all(
        product.media.map(async (media) => {
          if (mediaCache.has(media.url)) {
            return { ...media, mediaUrl: mediaCache.get(media.url) };
          } else {
            const mediaUrl = await fetchMedia(media.url);
            mediaCache.set(media.url, mediaUrl);
            return { ...media, mediaUrl };
          }
        })
      );
      setMediaUrls(urls);
    };

    loadMediaUrls();
  }, [product.media, mediaCache]);

  const renderActionButtons = useMemo(
    () =>
      (buttonsConfig[product.status] || buttonsConfig.default).map(
        ({ variant, action, label }) => (
          <Button
            key={action}
            variant={variant}
            onClick={() => handleSingleAction(product._id, action)}
            size="sm"
          >
            {label}
          </Button>
        )
      ),
    [product.status, handleSingleAction, product._id]
  );

  const productInfo = useMemo(
    () => [
      {
        label: "Created At",
        value: getFormattedDate(product.createdAt),
      },
      {
        label: "Product Name",
        value: product.name || undefined,
      },
      { label: "Description", value: product.description || undefined },
      { label: "Type", value: product.type },
      {
        label: "Categories",
        value: getCategoriesWithChild(product.categories),
      },
      { label: "Gross Weight", value: product.grossWeight },
      { label: "Net Weight", value: product.netWeight },
      { label: "Stone Weight", value: product.stoneWeight },
      { label: "Stone Charges", value: product.stoneCharges },
      { label: "Purity", value: product.purity || undefined },
      { label: "Stock", value: product.stock },
    ],
    [product]
  );

  return (
    <ListCard selected={selected} className="relative">
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-8">
          <span className="text-white text-xl">
            Updating this product (This might Take some time)...
          </span>
        </div>
      )}
      <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
        <div className="mb-2 lg:mb-0 w-full lg:w-auto">
          <h2 className="text-xl font-semibold">
            <span>Product Id:</span>{" "}
            <span className="text-primary font-bold">
              P-{product.productId}
            </span>
          </h2>
          <p className="text-sm text-neutral-dark">
            <span>Status:</span>{" "}
            <Badge variant={statusVariants[product.status] || "default"}>
              {product.status}
            </Badge>{" "}
          </p>
        </div>
        <div className="flex space-x-2 items-center justify-center w-full lg:w-auto">
          <Checkbox
            label=""
            name={`select-${product._id}`}
            checked={selected}
            onChange={() => handleSelectProduct(product._id)}
            size="md"
            className="mr-2"
          />
          <Button variant="primary" onClick={onEdit} size="sm">
            Edit
          </Button>
          {renderActionButtons}
        </div>
      </div>

      <div className="border-t pt-2">
        <div className="lg:flex">
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0 ">
            <InfoSection
              info={productInfo}
              itemClassName="mt-2 text-lg"
              valueClassName="font-bold"
            />
          </div>
          <h3 className="text-lg font-semibold mb-2">
            {mediaUrls.length <= 0 ? "" : "Medias:"}
          </h3>
          <div className="w-full lg:w-3/4 overflow-x-auto scrollbar-thin">
            {/* <h3 className="text-lg font-semibold mb-2">Medias</h3> */}
            <div className="flex space-x-2 overflow-x-auto pb-2 scrollbar-thin">
              {mediaUrls.length <= 0
                ? "No Media Uploaded."
                : mediaUrls.map((media, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0 max-w-[220px] p-2 text-text rounded-md"
                    >
                      <PreviewFile
                        preview={{ ...media, url: media.mediaUrl }}
                        index={index}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </ListCard>
  );
};

export default React.memo(ProductCard);
