import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/thunks/authThunks";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { FaUserCircle } from "react-icons/fa";
import ProfileModal from "../UI/ProfileModal";
import ThemeToggle from "../UI/ThemeToggle";
import Button from "../common/Button";

const Header = ({ toggleMenu, menuRoutes, getLinkClass }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const dispatch = useDispatch();
  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      dispatch(logout());
    }
  };

  return (
    <>
      <header className="bg-primary text-text-on-primary py-2 px-4 flex justify-between items-center w-full">
        <div className="flex items-center space-x-4">
          <button onClick={toggleMenu} className="focus:outline-none md:hidden">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
          {menuRoutes && (
            <nav className="hidden md:flex flex-row items-center space-x-4">
              {menuRoutes.map((route) => (
                <Link
                  key={route.path}
                  to={route.path}
                  className={`hover:border-text-on-primary border-2 border-transparent rounded-lg flex items-center p-1 transition duration-200 ${getLinkClass(route.path)}`}
                >
                  {/* <route.icon className="w-6 h-6" /> */}
                  <span className="px-2">{route.name}</span>
                </Link>
              ))}
            </nav>
          )}
        </div>
        <div className="flex items-center space-x-4">
          {/* <ThemeToggle /> */}
          <button
            onClick={openModal}
            className="flex items-center focus:outline-none"
          >
            <FaUserCircle className="w-8 h-8 text-text-on-primary" />
          </button>
          {menuRoutes && (
            <Button
              className="hidden  md:flex items-center bg-danger text-white rounded-lg p-2"
              onClick={handleLogout}
              variant="danger"
              icon={<ArrowRightOnRectangleIcon className="w-6 h-6" />}
              size="sm"
            >
              Logout
            </Button>
          )}
        </div>
      </header>

      <ProfileModal isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default Header;
