import React, { useState } from "react";
import Button from "../../../components/common/Button";
import Checkbox from "../../../components/common/Checkbox";
import Badge from "../../../components/common/Badge";
import { exportToExcel, exportToPDF } from "../../../utils/exportHelpers";
import { getFormattedDate } from "../../../utils/helpers";
import { displayStatus } from "../../../constants/order.constant";
import ListCard from "../../../components/UI/ListCard";
import InfoSection from "../../../components/UI/InfoSection"; // Adjust the import path as needed
import ProductModal from "./ProductModal"; // Import the new ProductModal component

const buttonsConfig = {
  Pending: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
    {
      variant: "primary-outline",
      action: "export-pdf",
      label: "Export to PDF",
    },
  ],
  Rejected: [{ variant: "success", action: "accept", label: "Accept" }],
  Cancelled: [{ variant: "danger-text", action: "delete", label: "Delete" }],
  Completed: [
    {
      variant: "primary-outline",
      action: "export-pdf",
      label: "Export to PDF",
    },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
  Accepted: [
    { variant: "danger", action: "reject", label: "Reject" },
    { variant: "primary", action: "complete", label: "Complete" },
    {
      variant: "primary-outline",
      action: "export-pdf",
      label: "Export to PDF",
    },
  ],
  default: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
    {
      variant: "primary-outline",
      action: "export-pdf",
      label: "Export to PDF",
    },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
};

const statusVariants = {
  Accepted: "success",
  Rejected: "danger",
  Pending: "warning",
  Cancelled: "secondary",
};

const exportOrderData = (order, exportFunc, exportType) => {
  const keyValueData = [
    { key: "Order ID", value: `O-${order.orderId}` },
    { key: "Date", value: getFormattedDate(order.orderDate) },
    {
      key: "Status",
      value: displayStatus[order.orderStatus] || order.orderStatus,
    },
    { key: "Total Items", value: order.totalItems },
    { key: "Total Gross Weight", value: `${order.totalGrossWeight}g` },
    { key: "Total Net Weight", value: `${order.totalNetWeight}g` },
    { key: "Total Stone Charges", value: order.totalStoneCharges },
    { key: "User Name", value: order.user.name },
    { key: "User Email", value: order.user.email },
    { key: "User Phone", value: order.user.phoneNumber },
    { key: "User GSTIN", value: order.user.gstin },
    {
      key: "User Address",
      value: `${order.user.address.line1}, ${order.user.address.city}, ${order.user.address.state}, ${order.user.address.pinCode}`,
    },
  ];

  const tableData = order.items.map((item) => ({
    ProductName: item.product.name,
    Type: item.product.type,
    Category: item.product.category,
    GrossWeight: item.grossWeight,
    NetWeight: item.netWeight,
    StoneCharges: item.stoneCharges,
  }));

  const columns = [
    "ProductName",
    "Type",
    "Category",
    "GrossWeight",
    "NetWeight",
    "StoneCharges",
  ];

  if (exportType === "excel") {
    exportFunc(keyValueData, tableData, `Order_${order.orderId}_Products`);
  } else if (exportType === "pdf") {
    exportFunc(
      keyValueData,
      tableData,
      columns,
      `Order_${order.orderId}_Products`
    );
  }
};

const OrderCard = ({
  order,
  handleSelectOrder,
  handleSingleAction,
  selected,
  currentFilter,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCartItem, setSelectedCartItem] = useState(null);

  const openModal = (cartItem) => {
    setSelectedCartItem(cartItem); // Pass only the product details
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCartItem(null);
    setIsModalOpen(false);
  };

  const renderActionButtons = () =>
    (buttonsConfig[currentFilter] || buttonsConfig.default).map(
      ({ variant, action, label }) => (
        <Button
          key={action}
          variant={variant}
          onClick={() => {
            if (action === "export-excel") {
              exportOrderData(order, exportToExcel, "excel");
            } else if (action === "export-pdf") {
              exportOrderData(order, exportToPDF, "pdf");
            } else {
              handleSingleAction(order._id, action);
            }
          }}
          size="sm"
        >
          {label}
        </Button>
      )
    );

  const orderInfo = [
    { label: "Total Items", value: order.totalItems },
    { label: "Total Gross Weight", value: `${order.totalGrossWeight}g` },
    { label: "Total Net Weight", value: `${order.totalNetWeight}g` },
    { label: "Total Stone Charges", value: order.totalStoneCharges },
  ];

  const userInfo = [
    { label: "Name", value: order.user.name },
    { label: "Email", value: order.user.email },
    { label: "Phone", value: order.user.phoneNumber },
    { label: "GSTIN", value: order.user.gstin },
    {
      label: "Address",
      value: `${order.user.address.line1}, ${order.user.address.city}, ${order.user.address.state}, ${order.user.address.pinCode}`,
    },
  ];

  return (
    <ListCard selected={selected}>
      <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
        <div className="mb-2 lg:mb-0">
          <h2 className="text-xl font-semibold">
            <span>Order Id:</span>{" "}
            <span className="text-primary-light">O-{order.orderId}</span>
          </h2>
          <p className="text-sm text-neutral-dark">
            <span>Status:</span>{" "}
            <Badge
              variant={
                statusVariants[displayStatus[order.orderStatus] || "default"]
              }
            >
              {displayStatus[order.orderStatus] || order.orderStatus}
            </Badge>{" "}
            <span>Date:</span>{" "}
            <span className="text-primary-light">
              {getFormattedDate(order.orderDate)}
            </span>
          </p>
        </div>
        <div className="flex space-x-2 items-center justify-center">
          <Checkbox
            label=""
            name={`select-${order._id}`}
            checked={selected}
            onChange={() => handleSelectOrder(order._id)}
            size="md"
            className="mr-2"
          />
          {renderActionButtons()}
        </div>
      </div>
      <div className="border-t pt-2 flex flex-col lg:flex-row">
        <div className="lg:w-1/2 pr-0 lg:pr-4 flex flex-col">
          <InfoSection info={orderInfo} />
          <InfoSection info={userInfo} title="User Details" />
        </div>
        <div className="lg:w-1/2 pl-0 lg:pl-4 overflow-x-auto mt-4 lg:mt-0 scrollbar-thin">
          <h3 className="text-lg font-semibold mb-2">Products</h3>
          <div className="flex space-x-2">
            {order.items.map((item) => (
              <div
                key={item._id}
                className="flex-shrink-0 min-w-[250px] p-4 bg-neutral-light text-text rounded-md"
              >
                <p className="text-sm font-semibold mb-1">
                  <span>{item.product.name}</span>
                </p>
                <InfoSection
                  info={[
                    { label: "Type", value: item.product.type },
                    { label: "Category", value: item.product.category },
                    { label: "Gross Weight", value: `${item.grossWeight}g` },
                    { label: "Net Weight", value: `${item.netWeight}g` },
                    { label: "Stone Charges", value: item.stoneCharges },
                  ]}
                  itemClassName="my-1"
                />
                <Button
                  variant="primary-outline"
                  size="sm"
                  className="mt-2"
                  onClick={() => openModal(item)}
                >
                  View Details
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedCartItem && (
        <ProductModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          selectedCartItem={selectedCartItem}
        />
      )}
    </ListCard>
  );
};

export default OrderCard;
