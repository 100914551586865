import { writeFile, utils } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportToExcel = (keyValueData, tableData, filename) => {
  // Create an array of arrays for key-value pairs
  const keyValueArray = keyValueData.map((item) => [item.key, item.value]);

  // Add an empty row between key-value pairs and table data
  keyValueArray.push([]);

  // Convert table data to array of arrays
  const tableArray = [Object.keys(tableData[0])].concat(
    tableData.map((item) => Object.values(item))
  );

  // Combine key-value pairs and table data
  const combinedData = keyValueArray.concat(tableArray);

  // Create a worksheet from the combined data
  const worksheet = utils.aoa_to_sheet(combinedData);

  // Create a new workbook and append the worksheet
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Order Details");

  // Write the workbook to a file
  writeFile(workbook, `${filename}.xlsx`);
};

export const exportToPDF = (keyValueData, tableData, columns, filename) => {
  const doc = new jsPDF();

  // Adding key-value pairs
  keyValueData.forEach((kv, index) => {
    doc.text(`${kv.key}: ${kv.value}`, 10, 10 + index * 10);
  });

  // Adding table
  doc.autoTable({
    head: [columns],
    body: tableData.map((item) => columns.map((column) => item[column])),
    startY: 10 + keyValueData.length * 10,
  });

  doc.save(`${filename}.pdf`);
};
