import { createSlice } from "@reduxjs/toolkit";
import {
  createProductThunk,
  updateProductByIdThunk,
  updateProductStatusByIdThunk,
  deleteProductByIdThunk,
  getProductByIdThunk,
  getAllProductsThunk,
  clearAdminProductError,
} from "../thunks/adminProductThunks";

const initialState = {
  products: [],
  product: null,
  loading: false,
  creating: false, // Added for create operation
  updatingCards: [],
  updating: false, // Added for update operation
  error: null,
};

const adminProductSlice = createSlice({
  name: "adminProduct",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearAdminProductError, (state) => {
        state.error = null;
      })
      .addCase(createProductThunk.pending, (state) => {
        state.creating = true;
      })
      .addCase(createProductThunk.fulfilled, (state, action) => {
        state.creating = false;
        state.products.push(action.payload);
      })
      .addCase(createProductThunk.rejected, (state, action) => {
        state.creating = false;
        state.error = action.payload;
      })
      .addCase(updateProductByIdThunk.pending, (state, action) => {
        state.updatingCards.push(action.meta.arg.id);
        state.updating = true;
      })
      .addCase(updateProductByIdThunk.fulfilled, (state, action) => {
        state.updatingCards = state.updatingCards.filter(
          (id) => id !== action.meta.arg.id
        );
        const index = state.products.findIndex(
          (product) => product._id === action.payload._id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
        state.updating = false;
      })
      .addCase(updateProductByIdThunk.rejected, (state, action) => {
        state.updatingCards = state.updatingCards.filter(
          (id) => id !== action.meta.arg.id
        );
        state.error = action.payload;
        state.updating = false;
      })
      .addCase(updateProductStatusByIdThunk.pending, (state, action) => {
        state.updatingCards.push(action.meta.arg.id);
        state.updating = true;
      })
      .addCase(updateProductStatusByIdThunk.fulfilled, (state, action) => {
        state.updatingCards = state.updatingCards.filter(
          (id) => id !== action.meta.arg.id
        );
        const index = state.products.findIndex(
          (product) => product._id === action.payload._id
        );
        if (index !== -1) {
          state.products[index].status = action.payload.status;
        }
        state.updating = false;
      })
      .addCase(updateProductStatusByIdThunk.rejected, (state, action) => {
        state.updatingCards = state.updatingCards.filter(
          (id) => id !== action.meta.arg.id
        );
        state.error = action.payload;
        state.updating = false;
      })
      .addCase(deleteProductByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProductByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.products = state.products.filter(
          (product) => product._id !== action.payload._id
        );
      })
      .addCase(deleteProductByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductByIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
      })
      .addCase(getProductByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllProductsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(getAllProductsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default adminProductSlice.reducer;
