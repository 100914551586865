import React from "react";

const Input = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  error,
  disabled = false, // Add disabled prop with default value
}) => {
  return (
    <div className="mb-1">
      <label className="block text-sm font-medium text-text">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled} // Pass the disabled prop to the input element
        className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${error ? "border-danger" : "border-neutral-dark"} ${disabled ? "bg-gray-100 cursor-not-allowed" : ""}`} // Add conditional classes for disabled state
      />
      <p className="text-sm text-danger" style={{ minHeight: "1.25rem" }}>
        {error || "\u00A0"}
      </p>
    </div>
  );
};

export default Input;
