import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, clearAuthError } from "../../store/thunks/authThunks";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../../components/common/Card";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import { FaCheck } from "react-icons/fa";
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import { ROUTE_PATHS } from "../../config/routesConfig";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || ROUTE_PATHS.ORDERS;
  const { loading, error, token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token && !error) {
      navigate(from, { replace: true });
    }
  }, [token, navigate]);

  const validate = useCallback(() => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [email, password]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (validate()) {
        dispatch(loginUser({ email, password }));
      }
    },
    [dispatch, email, password, validate]
  );

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value);
      if (errors.email) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
      }
      if (error) {
        dispatch(clearAuthError());
      }
    },
    [errors.email, error, dispatch]
  );

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
      if (errors.password) {
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      }
      if (error) {
        dispatch(clearAuthError());
      }
    },
    [errors.password, error, dispatch]
  );

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    if (error) {
      dispatch(clearAuthError());
    }
  }, [error, dispatch]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    if (error) {
      dispatch(clearAuthError());
    }
  }, [error, dispatch]);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-4">
      <Card title="Login" className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="p-2 mt-4 w-full">
          <div>
            <Input
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              error={errors.email}
              required
            />
          </div>
          <div>
            <Input
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
              error={errors.password}
              required
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            isLoading={loading}
            icon={<FaCheck />}
            className="mt-4 w-full"
          >
            Login
          </Button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>
      </Card>
      <div className="mt-4 text-center">
        <a
          href="#"
          className="text-blue-500 hover:underline"
          onClick={openModal}
        >
          Forgot Password?
        </a>
      </div>
      <ForgotPasswordModal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Login;
