import React from "react";
import Button from "../../../components/common/Button";
import Checkbox from "../../../components/common/Checkbox";
import Badge from "../../../components/common/Badge";
import ListCard from "../../../components/UI/ListCard";
import InfoSection from "../../../components/UI/InfoSection";

const buttonsConfig = {
  Pending: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
  ],
  Rejected: [{ variant: "success", action: "accept", label: "Accept" }],
  Blocked: [{ variant: "success", action: "unblock", label: "Unblock" }],
  Accepted: [{ variant: "danger-outline", action: "block", label: "Block" }],
  DeleteRequested: [
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
  Unverified: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
  ],
  default: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
    { variant: "danger-outline", action: "block", label: "Block" },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
};

const statusVariants = {
  accepted: "success",
  rejected: "danger",
  pending: "warning",
  blocked: "secondary",
  "pending-deletion": "secondary",
  unverified: "warning",
};

const statusMessages = {
  accepted: "Accepted",
  rejected: "Rejected",
  pending: "Pending",
  blocked: "Blocked",
  "pending-deletion": "Delete Requested",
  unverified: "Unverified",
};

const UserCard = ({
  user,
  handleSelectUser,
  handleSingleAction,
  selected,
  currentFilter,
  onEditAdminMessage,
}) => {
  const renderActionButtons = () =>
    (buttonsConfig[currentFilter] || buttonsConfig.default).map(
      ({ variant, action, label }) => (
        <Button
          key={action}
          variant={variant}
          onClick={() => handleSingleAction(user._id, action)}
          size="sm"
        >
          {label}
        </Button>
      )
    );

  const userInfo = [
    { label: "Email", value: user.email },
    { label: "Phone", value: user.phoneNumber },
    { label: "GSTIN", value: user.gstin },
    {
      label: "Address",
      value: `${user.address.line1}, ${user.address.city}, ${user.address.state}, ${user.address.pinCode}`,
    },
  ];

  return (
    <ListCard selected={selected}>
      <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
        <div className="mb-2 lg:mb-0">
          <h2 className="text-xl font-semibold">
            <span>User Name:</span>{" "}
            <span className="text-primary-light">{user.name}</span>
          </h2>
          <p className="text-sm text-neutral-dark">
            <span>Status:</span>{" "}
            <Badge variant={statusVariants[user.status] || "default"}>
              {statusMessages[user.status] || user.status}
            </Badge>{" "}
            <span>Date:</span>{" "}
            <span className="text-primary-light">
              {new Date(user.createdAt).toLocaleDateString()}
            </span>
          </p>
        </div>
        <div className="flex space-x-2 items-center justify-center">
          <Checkbox
            label=""
            name={`select-${user._id}`}
            checked={selected}
            onChange={() => handleSelectUser(user._id)}
            size="md"
            className="mr-2"
          />
          {renderActionButtons()}
        </div>
      </div>
      <div className="border-t pt-2 flex flex-col lg:flex-row">
        <div className="lg:w-1/2 pr-0 lg:pr-4 flex flex-col">
          <InfoSection info={userInfo} />
        </div>
        {(user.status === "blocked" || user.status === "rejected") && (
          <div className="lg:w-1/2 pl-0 lg:pl-4 flex flex-col mt-6 lg:mt-0">
            <div className="flex flex-col items-start">
              <p className="mb-2 font-semibold">Admin Message:</p>
              <p className="mb-2">
                {user.adminMessage || "No message provided"}
              </p>
              <Button
                variant="primary"
                size="sm"
                onClick={() => onEditAdminMessage(user)}
              >
                Edit
              </Button>
            </div>
          </div>
        )}
      </div>
    </ListCard>
  );
};

export default UserCard;
