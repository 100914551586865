import React from "react";

const Card = ({ title, children, className }) => {
  return (
    <div className={"bg-background shadow-md rounded-lg p-4 mb-4 " + className}>
      {title && (
        <h3 className="text-2xl text-center font-medium text-text mb-2">
          {title}
        </h3>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Card;
