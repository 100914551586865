import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductThunk,
  getProductByIdThunk,
  updateProductByIdThunk,
  clearAdminProductError,
} from "../../../store/thunks/adminProductThunks";
import { getAllCategoriesThunk } from "../../../store/thunks/categoryThunks";
import Button from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import TextArea from "../../../components/common/TextArea";
import Dropdown from "../../../components/common/Dropdown";
import FileUpload from "../../../components/common/FileUpload";
import PreviewFile from "../../../components/common/PreviewFile";
import { notifyError, notifySuccess } from "../../../utils/notification";
import { fetchMedia } from "../../../services/mediaService";
import {
  GENDER_OPTIONS,
  PRODUCT_STATUS_OPTIONS,
  PRODUCT_TYPES_OPTIONS,
} from "../../../constants/product.constant";
import Checkbox from "../../../components/common/Checkbox";

const LoadingModal = lazy(() => import("../../../components/UI/LoadingModal"));

const AdminProductEditCreateModal = ({ id, closeModal }) => {
  const dispatch = useDispatch();
  const {
    product,
    error,
    creating,
    updatingCards,
    loading: getProductLoading,
  } = useSelector((state) => state.adminProduct);
  const loading = updatingCards.includes(id) || creating;
  const { categories, loading: categoriesOptionsLoading } = useSelector(
    (state) => state.category
  );

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: PRODUCT_TYPES_OPTIONS[0],
    categories: [],
    narration: "",
    karigarCode: "",
    gender: GENDER_OPTIONS[0],
    status: PRODUCT_STATUS_OPTIONS[0],
    tags: "",
    images: [],
    videos: [],
    stock: 0,
    grossWeight: 0,
    stoneWeight: 0,
    stoneCharges: 0,
    netWeight: 0,
    purity: "",
    isStone: true,
  });

  const [initialData, setInitialData] = useState({});
  const [errors, setErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const mediaCache = useMemo(() => new Map(), []);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      dispatch(getProductByIdThunk(id));
    }
    dispatch(getAllCategoriesThunk());
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearAdminProductError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    const initializeFormData = async () => {
      if (product && isEditMode) {
        const loadMedia = async (media) => {
          if (mediaCache.has(media.url)) {
            return mediaCache.get(media.url);
          } else {
            const mediaUrl = await fetchMedia(media.url);
            mediaCache.set(media.url, mediaUrl);
            return mediaUrl;
          }
        };

        const images = await Promise.all(
          product.media
            .filter((m) => m.type === "image")
            .map(async (media) => {
              const mediaUrl = await loadMedia(media);
              return { ...media, previewUrl: mediaUrl };
            })
        );

        const videos = await Promise.all(
          product.media
            .filter((m) => m.type === "video")
            .map(async (media) => {
              const mediaUrl = await loadMedia(media);
              return { ...media, previewUrl: mediaUrl };
            })
        );

        setFormData({
          name: product.name || "",
          description: product.description || "",
          type:
            PRODUCT_TYPES_OPTIONS.find((type) => type === product.type) ||
            PRODUCT_TYPES_OPTIONS[0],
          categories: product.categories.map((category) => category._id) || [],
          narration: product.narration || "",
          karigarCode: product.karigarCode || "",
          gender:
            GENDER_OPTIONS.find((gender) => gender === product.gender) ||
            GENDER_OPTIONS[0],
          status:
            PRODUCT_STATUS_OPTIONS.find(
              (status) => status === product.status
            ) || PRODUCT_STATUS_OPTIONS[0],
          tags: product.tags.join(", ") || "",
          images: [],
          videos: [],
          stock: product.stock || 0,
          grossWeight: product.grossWeight || 0,
          stoneWeight: product.stoneWeight || 0,
          stoneCharges: product.stoneCharges || 0,
          netWeight: product.netWeight || 0,
          purity: product.purity || "",
          isStone: product.isStone || false,
        });

        setImageUrls(images);
        setVideoUrls(videos);
        setInitialData(product);
      }
    };

    if (product && isEditMode) {
      initializeFormData();
    }
  }, [product, isEditMode, mediaCache]);

  useEffect(() => {
    const calculateNetWeight = () => {
      if (formData.isStone) {
        setFormData((prevData) => ({
          ...prevData,
          netWeight: Math.abs(prevData.grossWeight - prevData.stoneWeight),
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          netWeight: prevData.grossWeight,
        }));
      }
    };
    calculateNetWeight();
  }, [formData.grossWeight, formData.stoneWeight, formData.isStone]);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  const handleDropdownChange = useCallback((name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  const handleMultiSelectChange = useCallback((name, values) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: values,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  const handleFileChange = useCallback((name, files) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: files,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.type) newErrors.type = "Type is required";
    if (formData.categories.length === 0)
      newErrors.categories = "At least one category is required";
    if (!formData.karigarCode)
      newErrors.karigarCode = "Karigar Code is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.status) newErrors.status = "Status is required";

    const currentImages = formData.images.filter((img) => !img.isRemoved);
    const existingImages = imageUrls.filter((img) => !img.isRemoved);

    if (currentImages.length + existingImages.length < 1) {
      newErrors.images = "At least one image is required";
    }

    return newErrors;
  }, [formData, imageUrls]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const newErrors = validateForm();

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      setShowLoadingModal(true);

      const { tags, images, videos, categories, ...rest } = formData;

      const productData = new FormData();
      Object.entries(rest).forEach(([key, value]) => {
        if (value !== initialData[key]) {
          productData.append(key, value);
        }
      });

      if (tags !== initialData.tags) {
        productData.append(
          "tags",
          JSON.stringify(tags.split(",").map((tag) => tag.trim()))
        );
      }

      if (categories !== initialData.categories) {
        productData.append("categories", JSON.stringify(categories));
      }

      images.forEach((image) => {
        if (image instanceof File) {
          productData.append("images", image);
        }
      });

      videos.forEach((video) => {
        if (video instanceof File) {
          productData.append("videos", video);
        }
      });

      if (isEditMode) {
        const mediaToRemove = [
          ...imageUrls.filter((img) => img.isRemoved).map((img) => img.url),
          ...videoUrls.filter((vid) => vid.isRemoved).map((vid) => vid.url),
        ];
        if (mediaToRemove.length > 0) {
          productData.append("mediaToRemove", JSON.stringify(mediaToRemove));
        }
      }

      const actionThunk = isEditMode
        ? updateProductByIdThunk({ id, productData })
        : createProductThunk(productData);

      dispatch(actionThunk)
        .unwrap()
        .then(() => {
          notifySuccess(
            `Product ${isEditMode ? "updated" : "created"} successfully!`
          );
          closeModal(true);
        })
        .catch((err) => {
          notifyError(err.message);
        })
        .finally(() => {
          setShowLoadingModal(false);
        });
    },
    [
      validateForm,
      formData,
      initialData,
      isEditMode,
      imageUrls,
      videoUrls,
      dispatch,
      closeModal,
      id,
    ]
  );

  const handleRemoveMedia = (type, index) => {
    const setStateCallback = (prev) => {
      const updated = [...prev];

      updated[index] = {
        ...updated[index],
        isRemoved: !updated[index].isRemoved,
      };

      return updated;
    };

    if (type === "image") {
      setImageUrls(setStateCallback);
    } else if (type === "video") {
      setVideoUrls(setStateCallback);
    }
  };

  const renderTypeDropdown = useMemo(
    () => (
      <Dropdown
        label="Type"
        options={PRODUCT_TYPES_OPTIONS.map((type) => ({
          _id: type,
          name: type,
        }))}
        selected={{ _id: formData.type, name: formData.type }}
        onChange={(value) => handleDropdownChange("type", value._id)}
        error={errors.type}
        isOptionsLoading={getProductLoading && !formData.type}
      />
    ),
    [formData.type, errors.type, getProductLoading]
  );

  const renderCategoriesDropdown = useMemo(() => {
    if (!categoriesOptionsLoading && categories.length > 0) {
      return (
        <Dropdown
          label="Categories"
          options={categories.map((category) => ({
            _id: category._id,
            name: `${[category.name, ...category.childCategories].join(" >> ")}`,
          }))}
          selected={formData.categories.map((categoryId) => {
            const category = categories.find((cat) => cat._id === categoryId);
            return {
              _id: categoryId,
              name:
                `${[category.name, ...category.childCategories].join(" >> ")}` ||
                "",
            };
          })}
          onChange={(value) =>
            handleMultiSelectChange(
              "categories",
              value.map((cat) => cat._id)
            )
          }
          error={errors.categories}
          isOptionsLoading={categoriesOptionsLoading}
          multiple
        />
      );
    }
    return null;
  }, [
    formData.categories,
    errors.categories,
    categoriesOptionsLoading,
    categories,
  ]);

  const renderGenderDropdown = useMemo(
    () => (
      <Dropdown
        label="Gender"
        options={GENDER_OPTIONS.map((gender) => ({
          _id: gender,
          name: gender,
        }))}
        selected={{ _id: formData.gender, name: formData.gender }}
        onChange={(value) => handleDropdownChange("gender", value._id)}
        error={errors.gender}
        isOptionsLoading={getProductLoading}
      />
    ),
    [formData.gender, errors.gender, getProductLoading]
  );

  const renderStatusDropdown = useMemo(
    () => (
      <Dropdown
        label="Status"
        options={PRODUCT_STATUS_OPTIONS.map((status) => ({
          _id: status,
          name: status,
        }))}
        selected={{ _id: formData.status, name: formData.status }}
        onChange={(value) => handleDropdownChange("status", value._id)}
        error={errors.status}
        isOptionsLoading={getProductLoading}
      />
    ),
    [formData.status, errors.status, getProductLoading]
  );

  return (
    <div className="container mx-auto bg-background mt-8">
      <form onSubmit={handleSubmit} className="space-y-4">
        <fieldset className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <Input
            label="Name"
            name="name"
            placeholder="Enter product name"
            value={formData.name}
            onChange={handleChange}
          />
          {renderTypeDropdown}
          {renderCategoriesDropdown}
          <Input
            label="Karigar Code"
            name="karigarCode"
            placeholder="Enter karigar code"
            value={formData.karigarCode}
            onChange={handleChange}
            error={errors.karigarCode}
          />
          {renderGenderDropdown}
          {renderStatusDropdown}
        </fieldset>
        <fieldset className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Input
            label="Tags (comma separated)"
            name="tags"
            placeholder="e.g. luxury, casual"
            value={formData.tags}
            onChange={handleChange}
          />
          <Input
            label="Narration"
            name="narration"
            placeholder="Enter narration"
            value={formData.narration}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset className="grid grid-cols-1 gap-4">
          <TextArea
            label="Description [Optional]"
            name="description"
            placeholder="Enter product description"
            value={formData.description}
            onChange={handleChange}
            error={errors.description}
          />
        </fieldset>

        <fieldset className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          <Input
            label="Stock"
            name="stock"
            type="number"
            placeholder="Enter stock quantity"
            value={formData.stock}
            onChange={handleChange}
            error={errors.stock}
          />
          <Input
            label="Gross Weight"
            name="grossWeight"
            type="number"
            placeholder="Enter gross weight"
            value={formData.grossWeight}
            onChange={handleChange}
            error={errors.grossWeight}
          />
          <Input
            label="Net Weight"
            name="netWeight"
            type="number"
            placeholder="Enter net weight"
            value={formData.netWeight}
            onChange={handleChange}
            error={errors.netWeight}
            disabled
          />
          <Input
            label="Purity"
            name="purity"
            placeholder="Enter purity (optional)"
            value={formData.purity}
            onChange={handleChange}
            error={errors.purity}
          />
          <label className="flex items-center h-20">
            <Checkbox
              type="checkbox"
              name="isStone"
              checked={formData.isStone}
              onChange={handleChange}
              className="mr-2"
              size="lg"
            />
            Is Stone
          </label>
          {formData.isStone && (
            <>
              <Input
                label="Stone Weight"
                name="stoneWeight"
                type="number"
                placeholder="Enter stone weight"
                value={formData.stoneWeight}
                onChange={handleChange}
                error={errors.stoneWeight}
                className="lg:col-span-2"
              />
              <Input
                label="Stone Charges"
                name="stoneCharges"
                type="number"
                placeholder="Enter stone charges"
                value={formData.stoneCharges}
                onChange={handleChange}
                error={errors.stoneCharges}
                className="lg:col-span-2"
              />
            </>
          )}
        </fieldset>
        {isEditMode && (
          <>
            <fieldset className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-10">
              <div>
                <h3 className="text-lg font-semibold mb-2">Uploaded Images</h3>
                <div className="flex flex-wrap -mx-2">
                  {imageUrls.map((preview, index) => (
                    <div
                      key={"img-" + index}
                      className={`relative w-full md:w-1/2 p-2 `}
                    >
                      <PreviewFile
                        key={index}
                        preview={preview}
                        index={index}
                        handleRemoveFile={() =>
                          handleRemoveMedia("image", index)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-2">Uploaded Videos</h3>
                <div className="flex flex-wrap -mx-2">
                  {videoUrls.map((preview, index) => (
                    <div
                      key={index}
                      className={`relative w-full md:w-1/2 p-2 `}
                    >
                      <PreviewFile
                        key={index}
                        preview={preview}
                        index={index}
                        handleRemoveFile={() =>
                          handleRemoveMedia("video", index)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </fieldset>
          </>
        )}
        <fieldset className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <FileUpload
            label="Images"
            name="images"
            onChange={(files) => handleFileChange("images", files)}
            multiple
            accept="image/*"
            maxFiles={4}
            minFiles={1}
            error={errors.images}
          />
          <FileUpload
            label="Videos"
            name="videos"
            onChange={(files) => handleFileChange("videos", files)}
            multiple
            accept="video/*"
            maxFiles={4}
          />
        </fieldset>

        <div className="flex justify-end">
          <Button type="submit" variant="primary" disabled={loading}>
            {loading
              ? "Processing..."
              : isEditMode
                ? "Update Product"
                : "Create Product"}
          </Button>
        </div>
      </form>
      <Suspense fallback={<div>Loading...</div>}>
        <LoadingModal
          isOpen={showLoadingModal}
          onClose={() => {
            closeModal(true);
            setShowLoadingModal(false);
          }}
        />
      </Suspense>
    </div>
  );
};

export default React.memo(AdminProductEditCreateModal);
