import React, { useState, useEffect } from "react";
import Modal from "../../../components/common/Modal";
import Checkbox from "../../../components/common/Checkbox";
import TextArea from "../../../components/common/TextArea";
import Button from "../../../components/common/Button";

const UserActionModal = ({
  isOpen,
  closeModal,
  action,
  userIds,
  allowUpdate,
  onSubmit,
  editingUser,
}) => {
  const [message, setMessage] = useState("");
  const [canUpdateProfile, setCanUpdateProfile] = useState(allowUpdate);

  useEffect(() => {
    if (editingUser && action === "editMessage") {
      setMessage(editingUser.adminMessage || "");
      setCanUpdateProfile(editingUser.canUpdateProfile || false);
    }
  }, [editingUser, action]);

  const handleSubmit = () => {
    onSubmit(message, canUpdateProfile);
    setMessage("");
    setCanUpdateProfile(false);
  };

  const handleClose = () => {
    setMessage("");
    setCanUpdateProfile(false);
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} closeModal={handleClose} title={`Confirm ${action}`}>
      <div className="flex flex-col space-y-4">
        <TextArea
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={4}
          placeholder={`Enter your message for the user${userIds.length > 1 ? "s" : ""}...`}
        />
        <Checkbox
          label="Allow user to update profile"
          checked={canUpdateProfile}
          onChange={(e) => setCanUpdateProfile(e.target.checked)}
        />
        <div className="flex justify-end space-x-2">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserActionModal;
