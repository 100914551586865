import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyDetailsThunk, logout } from "../store/thunks/authThunks";

const useAuth = () => {
  const dispatch = useDispatch();
  const { token, user, loading } = useSelector((state) => state.auth);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeAuth = async () => {
      if (token && !user && !loading && !initialized) {
        try {
          await dispatch(getMyDetailsThunk());
        } catch (err) {
          setError(err.message || "An error occurred");
          dispatch(logout());
        } finally {
          setInitialized(true);
        }
      } else if (!token) {
        setInitialized(true);
      }
    };

    initializeAuth();
  }, [token, user, loading, initialized, dispatch]);

  return {
    isAuthenticated: !!token && !!user && !loading && !error,
    loading,
    error,
  };
};

export default useAuth;
