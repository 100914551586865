import React, { useState, useEffect, useCallback } from "react";
import Input from "../../../components/common/Input";
import TextArea from "../../../components/common/TextArea";
import Button from "../../../components/common/Button";
import Dropdown from "../../../components/common/Dropdown";
import categoryService from "../../../services/categoryService";

const CategoryForm = ({ category, onSubmit, onCancel, serverError }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    tags: "",
    parentId: "",
    removeParent: false,
  });

  const [errors, setErrors] = useState({});
  const [dropdownCategories, setDropdownCategories] = useState([]);
  const [isOptionsLoading, setIsOptionsLoading] = useState(true);

  const fetchCategories = useCallback(async () => {
    try {
      const categories = await categoryService.getAllCategories({
        forDropdown: true,
        currentCategoryId: category?._id,
      });
      if (
        category?.parentId &&
        !categories.find((cat) => cat._id === category.parentId._id)
      ) {
        categories.push(category.parentId);
      }
      setDropdownCategories(categories);
      setIsOptionsLoading(false);
    } catch (error) {
      console.error("Error fetching categories for dropdown:", error);
      setIsOptionsLoading(false);
    }
  }, [category?._id, category?.parentId]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (category) {
      setFormData({
        name: category.name || "",
        description: category.description || "",
        tags: category.tags.join(", ") || "",
        parentId: category.parentId?._id || "",
        removeParent: false,
      });
    } else {
      setFormData({
        name: "",
        description: "",
        tags: "",
        parentId: "",
        removeParent: false,
      });
    }
  }, [category]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }, []);

  const handleParentChange = useCallback((value) => {
    setFormData((prevData) => ({
      ...prevData,
      parentId: value,
      removeParent: value === "" ? true : false,
    }));
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }
    return newErrors;
  }, [formData.name]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const formErrors = validateForm();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
      } else {
        onSubmit({
          ...formData,
          tags: formData.tags.split(",").map((tag) => tag.trim()),
        });
      }
    },
    [formData, onSubmit, validateForm]
  );

  const parentOptions = [
    { _id: "", name: "None" },
    ...dropdownCategories.map((cat) => ({
      _id: cat._id,
      name: cat.name,
    })),
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-6">
        <Dropdown
          label="Parent Category"
          options={parentOptions}
          selected={formData.parentId}
          onChange={handleParentChange}
          isOptionsLoading={isOptionsLoading}
        />
      </div>
      <div>
        <Input
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter category name"
          error={errors.name}
        />
      </div>
      <div>
        <TextArea
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Enter category description"
        />
      </div>
      <div>
        <Input
          label="Tags (comma separated)"
          name="tags"
          value={formData.tags}
          onChange={handleChange}
          placeholder="Enter tags"
        />
      </div>

      {serverError && <p className="text-red-500">{serverError}</p>}
      <div className="flex justify-end">
        <Button
          type="button"
          variant="secondary"
          onClick={onCancel}
          className="mr-2"
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          {category ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default React.memo(CategoryForm);
