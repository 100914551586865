import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import adminService from "../../services/adminService";

export const getAdminThunk = createAsyncThunk(
  "admin/getAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.getAdmin(id);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const createAdminThunk = createAsyncThunk(
  "admin/createAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const response = await adminService.createAdmin(adminData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteAdminThunk = createAsyncThunk(
  "admin/deleteAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.deleteAdmin(id);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateAdminPasswordThunk = createAsyncThunk(
  "admin/updateAdminPassword",
  async (passwordData, { rejectWithValue }) => {
    try {
      const response = await adminService.updateAdminPassword(passwordData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "admin/resetPassword",
  async (passwordData, { rejectWithValue }) => {
    try {
      const response = await adminService.resetPassword(passwordData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAdminsThunk = createAsyncThunk(
  "admin/fetchAdmins",
  async (filter, { rejectWithValue }) => {
    try {
      const response = await adminService.fetchAdmins(filter);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const toggleBlockAdminThunk = createAsyncThunk(
  "admin/toggleBlockAdmin",
  async ({ adminId, block }, { rejectWithValue }) => {
    try {
      const response = await adminService.toggleBlockAdmin(adminId, block);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const clearAdminError = createAction("admin/clearAdminError");
