import React from "react";
import Button from "../../../components/common/Button";
import ListCard from "../../../components/UI/ListCard";
import InfoSection from "../../../components/UI/InfoSection";
const CategoryCard = ({ category, handleSingleAction }) => {
  const info = [
    { label: "Name", value: category.name },
    { label: "Description", value: category.description },
    { label: "Tags", value: category.tags.join(", ").trim() || undefined },
    { label: "Parent Category", value: category.parentId?.name || "None" },
  ];

  return (
    <ListCard>
      <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
        <div className="mb-2 lg:mb-0">
          <h2 className="text-xl font-semibold">
            <span>Category Id:</span>{" "}
            <span className="text-primary-light">C-{category.categoryId}</span>
          </h2>
        </div>
        <div className="flex space-x-2 items-center justify-center">
          <Button
            variant="primary-outline"
            onClick={() => handleSingleAction(category._id, "edit")}
          >
            Edit
          </Button>
          <Button
            variant="danger-text"
            onClick={() => handleSingleAction(category._id, "delete")}
          >
            Delete
          </Button>
        </div>
      </div>
      <InfoSection info={info} className="border-t pt-2 " />
    </ListCard>
  );
};

export default CategoryCard;
