import React from "react";

const Button = ({
  children,
  onClick,
  variant = "primary",
  type = "button",
  size = "md",
  isLoading = false,
  disabled = false,
  icon = null,
  className = "",
}) => {
  const baseStyles =
    "rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center justify-center";

  const variantStyles = {
    primary:
      "text-text-on-primary bg-primary hover:bg-primary-dark focus:ring-primary",
    secondary:
      "text-color-text bg-secondary hover:bg-secondary-dark focus:ring-secondary",
    success:
      "text-text-on-primary bg-success hover:bg-success-dark focus:ring-success",
    danger:
      "text-text-on-primary bg-danger hover:bg-danger-dark focus:ring-danger",
    warning:
      "text-text-on-primary bg-warning hover:bg-warning-dark focus:ring-warning",
    "primary-outline":
      "text-primary bg-transparent border border-primary hover:bg-primary hover:text-text-on-primary focus:ring-primary",
    "secondary-outline":
      "text-secondary bg-transparent border border-secondary hover:bg-secondary hover:text-text-on-primary focus:ring-secondary",
    "success-outline":
      "text-success bg-transparent border border-success hover:bg-success hover:text-text-on-primary focus:ring-success",
    "danger-outline":
      "text-danger bg-transparent border border-danger hover:bg-danger hover:text-text-on-primary focus:ring-danger",
    "warning-outline":
      "text-warning bg-transparent border border-warning hover:bg-warning hover:text-text-on-primary focus:ring-warning",
    "primary-text":
      "text-primary bg-transparent hover:bg-primary-light focus:ring-primary",
    "secondary-text":
      "text-secondary bg-transparent hover:bg-secondary-light focus:ring-secondary",
    "success-text":
      "text-success bg-transparent hover:bg-success-light focus:ring-success",
    "danger-text":
      "text-danger bg-transparent border border-transparent hover:border-danger focus:ring-danger",
    "warning-text":
      "text-warning bg-transparent hover:bg-warning-light focus:ring-warning",
  };

  const sizeStyles = {
    sm: "px-2 py-1 text-xs",
    md: "px-4 py-2 text-sm",
    lg: "px-6 py-3 text-lg",
  };

  const spinnerColor = {
    primary: "text-white",
    secondary: "text-black",
    success: "text-white",
    danger: "text-white",
    warning: "text-black",
    "primary-outline": "text-primary",
    "secondary-outline": "text-secondary",
    "success-outline": "text-success",
    "danger-outline": "text-danger",
    "warning-outline": "text-warning",
    "primary-text": "text-primary",
    "secondary-text": "text-secondary",
    "success-text": "text-success",
    "danger-text": "text-danger",
    "warning-text": "text-warning",
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${
        isLoading || disabled ? "cursor-not-allowed opacity-50" : ""
      } ${className}`}
      disabled={isLoading || disabled}
    >
      {isLoading && (
        <svg
          className={`animate-spin h-5 w-5 ${spinnerColor[variant]} mr-2`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {icon && !isLoading && (
        <span className={`${children ? "mr-2" : ""}`}>{icon}</span>
      )}
      {children}
    </button>
  );
};

export default Button;
