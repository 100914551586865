import api from "./api";

const createProduct = async (productData) => {
  const response = await api.post("/admin-product", productData);
  return response.data;
};

const updateProductById = async (id, productData) => {
  const response = await api.patch(`/admin-product/${id}`, productData);
  return response.data;
};

const updateProductStatusById = async (id, status) => {
  const response = await api.patch(`/admin-product/${id}/status`, { status });
  return response.data;
};

const deleteProductById = async (id) => {
  const response = await api.delete(`/admin-product/${id}`);
  return response.data;
};

const getProductById = async (id) => {
  const response = await api.get(`/admin-product/${id}`);
  return response.data;
};

const getAllProducts = async (filters) => {
  const response = await api.get("/admin-product", { params: filters });
  return response.data;
};

const adminProductService = {
  createProduct,
  updateProductById,
  updateProductStatusById,
  deleteProductById,
  getProductById,
  getAllProducts,
};

export default adminProductService;
