import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestAccountDeleteThunk,
  clearUserError,
} from "../../store/thunks/userThunks";
import Card from "../../components/common/Card";
import Button from "../../components/common/Button";
import Input from "../../components/common/Input";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const RequestAccountDeletePage = () => {
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.user);

  const validate = useCallback(() => {
    const newErrors = {};
    if (!password) newErrors.password = "Password is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone number is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [password, phoneNumber]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (validate()) {
        dispatch(requestAccountDeleteThunk({ password, phoneNumber })).then(
          (result) => {
            if (result.type === "user/requestAccountDelete/fulfilled") {
              setSuccessMessage(
                "Your account deletion request has been submitted successfully."
              );
            }
          }
        );
      }
    },
    [dispatch, password, phoneNumber, validate, navigate]
  );

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
      if (errors.password) {
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      }
      if (error) {
        dispatch(clearUserError());
      }
    },
    [errors.password, error, dispatch]
  );

  const handlePhoneNumberChange = useCallback(
    (e) => {
      setPhoneNumber(e.target.value);
      if (errors.phoneNumber) {
        setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
      }
      if (error) {
        dispatch(clearUserError());
      }
    },
    [errors.phoneNumber, error, dispatch]
  );

  const handleCloseTab = () => {
    window.close();
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-4">
      <Card title="Request Account Deletion" className="w-full max-w-md">
        {successMessage ? (
          <div className="p-2 mt-4 w-full text-center">
            <p className="text-green-500 mt-2">{successMessage}</p>
            <Button
              variant="primary"
              onClick={handleCloseTab}
              className="mt-4 w-full"
            >
              Now You can Close the Tab
            </Button>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="p-2 mt-4 w-full">
            <div>
              <Input
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter your password"
                error={errors.password}
                required
              />
            </div>
            <div>
              <Input
                label="Phone Number"
                name="phoneNumber"
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Enter your phone number"
                error={errors.phoneNumber}
                required
              />
            </div>
            <Button
              type="submit"
              variant="primary"
              isLoading={loading}
              icon={<FaCheck />}
              className="mt-4 w-full"
            >
              Submit Request
            </Button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </form>
        )}
      </Card>
    </div>
  );
};

export default RequestAccountDeletePage;
