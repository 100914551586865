import React from "react";
import classNames from "classnames";

const Checkbox = ({
  label,
  name,
  checked,
  onChange,
  className = "",
  size = "md",
}) => {
  const sizeClasses = {
    sm: "h-3 w-3",
    md: "h-4 w-4",
    lg: "h-5 w-5",
  };

  return (
    <div className={classNames("flex items-center", className)}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className={classNames(
          "form-checkbox rounded focus:ring focus:ring-offset-0 focus:ring-opacity-50",
          sizeClasses[size]
        )}
        style={{
          accentColor: "var(--color-primary)",
          borderColor: "var(--color-neutral-dark)",
          backgroundColor: checked ? "var(--color-primary)" : "transparent",
        }}
      />
      {label && (
        <label htmlFor={name} className={classNames("ml-2 text-text")}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
