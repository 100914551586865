import api from "./api";

const ordersMock = [
  {
    orderStatus: "AdminAccepted",
    _id: "665c96be7f1cef52c48dfgb6a92",
    userId: "665c96bd7f1cef52c48b6a7d",
    user: {
      email: "user@user.com",
      name: "User",
      phoneNumber: "1234567890",
      gstin: "22AAAAA0000A1Z5",
      address: {
        _id: "665c96bd7f1cef52c48b6a7e",
        line1: "123 Main St",
        line2: "",
        city: "Anytown",
        state: "Anystate",
        pinCode: "123456",
      },
    },
    items: [
      {
        _id: "665c96be7f1cef52c48b6a8c",
        product: {
          tags: ["test", "product1"],
          _id: "665c96be7f1cef52c48b6a88",
          name: "Product 1",
          description: "Description 1",
          type: "GOLD",
          category: "Ring",
          media: [],
          narration: "Narration 1",
          karigarCode: "K123",
          gender: "UNISEX",
          status: "ACTIVE",
        },
        variant: {
          _id: "665c96be7f1cef52c48b6a89",
          variantName: "Variant 1",
          variantPrice: 1000,
          variantStock: 10,
          grossWeight: 20,
          stoneWeight: 2,
          stoneCharges: 200,
          netWeight: 18,
          priority: "1",
        },
        grossWeight: 20,
        netWeight: 18,
        stoneCharges: 200,
      },
      {
        _id: "665c96be7f1cef52c48bdfg6a8c",
        product: {
          tags: ["test", "product1"],
          _id: "665c96be7f1cef52c48b6a88",
          name: "Product 1",
          description: "Description 1",
          type: "GOLD",
          category: "Ring",
          media: [],
          narration: "Narration 1",
          karigarCode: "K123",
          gender: "UNISEX",
          status: "ACTIVE",
        },
        variant: {
          _id: "665c96be7f1cef52c48b6a89",
          variantName: "Variant 1",
          variantPrice: 1000,
          variantStock: 10,
          grossWeight: 20,
          stoneWeight: 2,
          stoneCharges: 200,
          netWeight: 18,
          priority: "1",
        },
        grossWeight: 20,
        netWeight: 18,
        stoneCharges: 200,
      },
      {
        _id: "665c96be7f1cef52c48b6a8c",
        product: {
          tags: ["test", "product1"],
          _id: "665c96be7f1cef52c48b6a88",
          name: "Product 1",
          description: "Description 1",
          type: "GOLD",
          category: "Ring",
          media: [],
          narration: "Narration 1",
          karigarCode: "K123",
          gender: "UNISEX",
          status: "ACTIVE",
        },
        variant: {
          _id: "665c96be7f1cef52c48b6a89",
          variantName: "Variant 1",
          variantPrice: 1000,
          variantStock: 10,
          grossWeight: 20,
          stoneWeight: 2,
          stoneCharges: 200,
          netWeight: 18,
          priority: "1",
        },
        grossWeight: 20,
        netWeight: 18,
        stoneCharges: 200,
      },
    ],
    totalItems: 1,
    totalGrossWeight: 20,
    totalNetWeight: 18,
    totalStoneCharges: 200,
  },
  {
    orderStatus: "AdminAccepted",
    _id: "665c96be7f1cefdfg52c48b6a92",
    userId: "665c96bd7f1cef52c48b6a7d",
    user: {
      email: "user@user.com",
      name: "User",
      phoneNumber: "1234567890",
      gstin: "22AAAAA0000A1Z5",
      address: {
        _id: "665c96bd7f1cef52c48b6a7e",
        line1: "123 Main St",
        line2: "",
        city: "Anytown",
        state: "Anystate",
        pinCode: "123456",
      },
    },
    items: [
      {
        _id: "665c96be7f1cef52c48b6a8c",
        product: {
          tags: ["test", "product1"],
          _id: "665c96be7f1cef52c48b6a88",
          name: "Product 1",
          description: "Description 1",
          type: "GOLD",
          category: "Ring",
          media: [],
          narration: "Narration 1",
          karigarCode: "K123",
          gender: "UNISEX",
          status: "ACTIVE",
        },
        variant: {
          _id: "665c96be7f1cef52c48b6a89",
          variantName: "Variant 1",
          variantPrice: 1000,
          variantStock: 10,
          grossWeight: 20,
          stoneWeight: 2,
          stoneCharges: 200,
          netWeight: 18,
          priority: "1",
        },
        grossWeight: 20,
        netWeight: 18,
        stoneCharges: 200,
      },
    ],
    totalItems: 1,
    totalGrossWeight: 20,
    totalNetWeight: 18,
    totalStoneCharges: 200,
  },
  {
    orderStatus: "AdminAccepted",
    _id: "665c96be7f1cefdfg5zXC2c48b6a92",
    userId: "665c96bd7f1cef52c48b6a7d",
    user: {
      email: "user@user.com",
      name: "User",
      phoneNumber: "1234567890",
      gstin: "22AAAAA0000A1Z5",
      address: {
        _id: "665c96bd7f1cef52c48b6a7e",
        line1: "123 Main St",
        line2: "",
        city: "Anytown",
        state: "Anystate",
        pinCode: "123456",
      },
    },
    items: [
      {
        _id: "665c96be7f1cef52c48b6a8c",
        product: {
          tags: ["test", "product1"],
          _id: "665c96be7f1cef52c48b6a88",
          name: "Product 1",
          description: "Description 1",
          type: "GOLD",
          category: "Ring",
          media: [],
          narration: "Narration 1",
          karigarCode: "K123",
          gender: "UNISEX",
          status: "ACTIVE",
        },
        variant: {
          _id: "665c96be7f1cef52c48b6a89",
          variantName: "Variant 1",
          variantPrice: 1000,
          variantStock: 10,
          grossWeight: 20,
          stoneWeight: 2,
          stoneCharges: 200,
          netWeight: 18,
          priority: "1",
        },
        grossWeight: 20,
        netWeight: 18,
        stoneCharges: 200,
      },
    ],
    totalItems: 1,
    totalGrossWeight: 20,
    totalNetWeight: 18,
    totalStoneCharges: 200,
  },
];

const getAllOrdersForAdmin = async (filters) => {
  const response = await api.get("/orders/admin", { params: filters });

  console.log({ response });
  return response.data;

  return ordersMock;
};

const updateMultipleOrderStatusByAdmin = async (orderData) => {
  const response = await api.put("/orders/status/admin", orderData);
  return response.data;
};

const deleteMultipleOrdersByAdmin = async (orderIds) => {
  const response = await api.delete("/orders/admin", { data: { orderIds } });
  return response.data;
};

const orderService = {
  getAllOrdersForAdmin,
  updateMultipleOrderStatusByAdmin,
  deleteMultipleOrdersByAdmin,
};

export default orderService;
