import React from "react";

const Loader = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-neutral-light h-12 w-12"></div>
      <style jsx>{`
        .loader {
          border-top-color: var(--color-primary);
          animation: spinner 1.5s linear infinite;
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;
