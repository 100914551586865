import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import adminSlice from "./slices/adminSlice";
import orderSlice from "./slices/orderSlice";
import categorySlice from "./slices/categorySlice";
import adminProductSlice from "./slices/adminProductSlice";
import userSlice from "./slices/userSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userSlice,
  admin: adminSlice,
  orders: orderSlice,
  category: categorySlice,
  adminProduct: adminProductSlice,
});

export default rootReducer;
