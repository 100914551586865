import React, { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Modal from "../common/Modal";

const PreviewFile = ({ preview, index, handleRemoveFile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const fileType = preview?.file?.type || preview?.type;
  const isImage =
    (fileType && fileType?.startsWith("image")) ||
    preview?.url?.match(/\.(jpg|jpeg|png)$/);
  const isVideo =
    (fileType && fileType?.startsWith("video")) ||
    preview?.url?.match(/\.(mp4|mov)$/);

  const opacityClass = preview.isRemoved ? "opacity-20" : "";
  const previewUrl = preview.previewUrl || preview.url;

  const openModal = () => {
    if (isVideo) {
      setSelectedMedia(previewUrl);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMedia(null);
  };

  return (
    <>
      {isImage && (
        <Zoom>
          <img
            src={previewUrl}
            alt="preview"
            className={`w-full h-full object-cover rounded-lg shadow-md ${opacityClass}`}
          />
        </Zoom>
      )}
      {isVideo && (
        <div onClick={openModal} className="cursor-pointer h-full">
          <video
            src={previewUrl}
            controls
            className={`w-full h-full object-cover rounded-lg shadow-md ${opacityClass}`}
          />
        </div>
      )}
      {handleRemoveFile && (
        <button
          type="button"
          onClick={() => handleRemoveFile(preview.type, index)}
          className={`absolute top-0 right-0 p-1 rounded-full text-danger-dark hover:text-danger-light ${preview.isRemoved ? " bg-success " : "bg-white"}`}
          aria-label={preview.isRemoved ? "Add file" : "Remove file"}
        >
          {preview.isRemoved ? (
            <FaPlus className="text-text-on-primary" />
          ) : (
            <FaTimes />
          )}
        </button>
      )}
      {selectedMedia && (
        <Modal isOpen={isModalOpen} closeModal={closeModal} isFullScreen>
          <div className="h-full w-full flex justify-center items-center">
            <video
              src={selectedMedia}
              className="max-h-full max-w-full"
              controls
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default PreviewFile;
