import React from "react";

const ListCard = ({ children, selected }) => (
  <div
    className={`bg-background shadow-md rounded-lg p-4 text-text relative ${selected ? "border-l-4 border-r-4 border-primary" : ""}`}
  >
    {children}
  </div>
);

export default ListCard;
