import React, { useState } from "react";
import Button from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import Checkbox from "../../../components/common/Checkbox";

const AdminResetPasswordModal = ({ onSubmit, admin }) => {
  const [formData, setFormData] = useState({
    newPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const validate = () => {
    let tempErrors = {};
    if (!formData.newPassword) tempErrors.newPassword = "Password is required";
    if (formData.newPassword.length < 6)
      tempErrors.newPassword = "Must be at least 6 char long";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      copyCredentials();
      onSubmit({ id: admin._id, newPassword: formData.newPassword });
    }
  };

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setFormData({
      ...formData,
      newPassword: randomPassword,
    });
  };

  const copyCredentials = () => {
    const credentials = `Email: ${admin.email}\nName: ${admin.name}\nPhone Number: ${admin.phoneNumber}\nNew Password: ${formData.newPassword}`;
    navigator.clipboard.writeText(credentials);
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <div className="flex items-start space-x-4">
        <div className="flex-1">
          <Input
            label="New Password"
            type={showPassword ? "text" : "password"}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            error={errors.newPassword}
            placeholder="Enter new password"
          />
        </div>
        <div className="mt-6">
          <Button variant="secondary" onClick={generatePassword}>
            Generate Password
          </Button>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <Checkbox
          checked={showPassword}
          onChange={(e) => setShowPassword(e.target.checked)}
          className=""
        />
        <label htmlFor="show-password" className="text-sm font-medium">
          View Password
        </label>
      </div>
      <div className="mb-4">
        <div className="text-sm">
          <strong>Email:</strong> {admin.email}
        </div>
        <div className="text-sm">
          <strong>Name:</strong> {admin.name}
        </div>
        <div className="text-sm">
          <strong>Phone Number:</strong> {admin.phoneNumber}
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        <Button variant="primary" type="submit">
          Copy and Update
        </Button>
      </div>
    </form>
  );
};

export default AdminResetPasswordModal;
