// src/store/thunks/authThunks.js
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import authService from "../../services/authService";

export const getMyDetailsThunk = createAsyncThunk(
  "admin/getMyDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.getMyDetails();
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk Actions
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await authService.login(
        credentials.email,
        credentials.password
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  "auth/requestPasswordReset",
  async (email, { rejectWithValue }) => {
    try {
      const response = await authService.requestPasswordReset(email);
      return response;
    } catch (error) {
      const errorMessage =
        error.response?.data?.displayMessage ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      return {}; // Return an empty object to handle the fulfilled case correctly
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const clearAuthError = createAction("auth/clearAuthError");
export const clearPasswordResetMessage = createAction(
  "auth/clearPasswordResetMessage"
);
