// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./components/UI/ErrorBoundary";
import { routes } from "./config/routesConfig";

const App = () => {
  return (
    <ErrorBoundary>
      <ToastContainer />
      <Router>
        <Routes>
          {routes.map((route, index) => {
            const { element, children, ...rest } = route;
            return (
              <Route key={index} element={element} {...rest}>
                {children &&
                  children.map((child, childIndex) => {
                    const {
                      element: childElement,
                      children: subChildren,
                      ...childRest
                    } = child;
                    return (
                      <Route
                        key={childIndex}
                        element={childElement}
                        {...childRest}
                      >
                        {subChildren &&
                          subChildren.map((subChild, subChildIndex) => (
                            <Route
                              key={subChildIndex}
                              element={subChild.element}
                              {...subChild}
                            />
                          ))}
                      </Route>
                    );
                  })}
              </Route>
            );
          })}
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
