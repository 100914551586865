import api from "./api";

const createCategory = async (categoryData) => {
  const response = await api.post("/admin-categories", categoryData);
  return response.data;
};

const getAllCategories = async (filters = {}) => {
  const response = await api.get("/admin-categories", { params: filters });
  return response.data;
};

const getCategoryById = async (categoryId) => {
  const response = await api.get(`/admin-categories/${categoryId}`);
  return response.data;
};

const updateCategoryById = async (categoryId, categoryData) => {
  const response = await api.put(
    `/admin-categories/${categoryId}`,
    categoryData
  );
  return response.data;
};

const deleteCategoryById = async (categoryId) => {
  const response = await api.delete(`/admin-categories/${categoryId}`);
  return response.data;
};

const categoryService = {
  createCategory,
  getAllCategories,
  getCategoryById,
  updateCategoryById,
  deleteCategoryById,
};

export default categoryService;
