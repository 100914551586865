import React, { useState } from "react";
import Modal from "../common/Modal";
import { useSelector } from "react-redux";
import Button from "../common/Button";
import UpdatePasswordModal from "./UpdatePasswordModal";

const ProfileModal = ({ isOpen, closeModal }) => {
  const { user } = useSelector((state) => state.auth);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const openPasswordModal = () => setIsPasswordModalOpen(true);
  const closePasswordModal = () => setIsPasswordModalOpen(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        title="Admin Profile Details"
      >
        <div className="mt-4 space-y-4 text-text">
          <div>
            <label className="block text-sm font-medium text-neutral-dark ">
              Name
            </label>
            <p className="mt-1 text-lg font-semibold text-primary">
              {user.name}
            </p>
          </div>
          <div>
            <label className="block text-sm font-medium text-neutral-dark ">
              Email
            </label>
            <p className="mt-1 text-lg font-semibold text-primary">
              {user.email}
            </p>
          </div>
          {user.phoneNumber && (
            <div>
              <label className="block text-sm font-medium text-neutral-dark ">
                Phone
              </label>
              <p className="mt-1 text-lg font-semibold text-primary">
                {user.phoneNumber}
              </p>
            </div>
          )}
        </div>
        <div className="mt-6 flex justify-end">
          <Button variant="secondary" onClick={openPasswordModal}>
            Change Password
          </Button>
        </div>
      </Modal>

      {isPasswordModalOpen && (
        <UpdatePasswordModal
          isOpen={isPasswordModalOpen}
          closeModal={closePasswordModal}
        />
      )}
    </>
  );
};

export default ProfileModal;
