import React, { useState } from "react";
import Button from "./Button"; // Adjust the path as necessary
import PreviewFile from "./PreviewFile"; // Adjust the path as necessary

const FileUpload = ({
  label,
  name,
  onChange,
  multiple,
  accept,
  maxFiles,
  minFiles,
  error: fromError,
}) => {
  const [previews, setPreviews] = useState([]);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const acceptedTypes = accept.split(",");

    // Validate file types
    const invalidFiles = files.filter(
      (file) =>
        !acceptedTypes.some((type) => file.type.includes(type.replace("*", "")))
    );
    if (invalidFiles.length > 0) {
      setError(`Some files are not of the accepted type: ${accept}`);
      return;
    }

    // Check if the number of selected files exceeds the maxFiles limit
    if (maxFiles && previews.length + files.length > maxFiles) {
      setError(`You can only upload up to ${maxFiles} files.`);
      return;
    }

    const previewFiles = files.map((file) => {
      const previewURL = URL.createObjectURL(file);
      return { file, url: previewURL };
    });

    setPreviews((prevPreviews) => [...prevPreviews, ...previewFiles]);
    onChange(previews.map((preview) => preview.file).concat(files));
    setError("");
  };

  const handleRemoveFile = (fileType, index) => {
    const updatedPreviews = previews.filter((_, i) => i !== index);
    setPreviews(updatedPreviews);
    onChange(updatedPreviews.map((preview) => preview.file));

    // Check if the number of remaining files is below the minFiles limit
    if (minFiles && updatedPreviews.length < minFiles) {
      setError(`You need to upload at least ${minFiles} files.`);
    } else {
      setError("");
    }
  };

  return (
    <div className="file-upload">
      <label className="block font-semibold mb-2 text-primary">{label}</label>
      <div className="mb-4 flex items-center">
        <label className="hidden">
          <input
            type="file"
            name={name}
            onChange={handleFileChange}
            multiple={multiple}
            accept={accept}
          />
        </label>
        <Button
          onClick={() =>
            document.querySelector(`input[name="${name}"]`).click()
          }
          variant="primary-outline"
          disabled={previews.length >= maxFiles}
        >
          Upload Files
        </Button>
        <span className="ml-4 text-neutral-dark">
          {previews.length} files uploaded
        </span>
      </div>
      {(fromError || error) && (
        <div className="text-danger mb-4">{fromError || error}</div>
      )}
      <div className="previews flex flex-wrap -mx-2">
        {previews.map((preview, index) => (
          <div key={index} className={`relative w-full md:w-1/2 p-2 `}>
            <PreviewFile
              key={index}
              preview={preview}
              index={index}
              handleRemoveFile={handleRemoveFile}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUpload;
