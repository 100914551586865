import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminsThunk,
  deleteAdminThunk,
  toggleBlockAdminThunk,
  clearAdminError,
  createAdminThunk,
  updateAdminPasswordThunk,
} from "../../store/thunks/adminThunks";
import Button from "../../components/common/Button";
import Filter from "../../components/common/Filter";
import { notifyError } from "../../utils/notification";
import AdminCard from "./components/AdminCard";
import Modal from "../../components/common/Modal";
import AdminCreateModal from "./components/AdminCreateModal";
import AdminResetPasswordModal from "./components/AdminResetPasswordModal";

const adminFilterStatus = {
  All: {},
  ForgotPasswordRequests: { forgotPassword: true },
  Blocked: { isBlocked: true },
};

const AdminManagement = () => {
  const dispatch = useDispatch();
  const { isRootAdmin } = useSelector((state) => state.auth);
  const { admins, loading, error } = useSelector((state) => state.admin);

  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("All");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createdAdmin, setCreatedAdmin] = useState(null);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [adminForReset, setAdminForReset] = useState(null);

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearAdminError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(fetchAdminsThunk(adminFilterStatus[currentFilter]));
  }, [dispatch, currentFilter]);

  const handleFilterChange = (status) => {
    setCurrentFilter(status);
    dispatch(fetchAdminsThunk(adminFilterStatus[status]));
    setSelectedAdmins([]);
  };

  const handleSelectAdmin = (adminId) => {
    setSelectedAdmins((prevSelected) =>
      prevSelected.includes(adminId)
        ? prevSelected.filter((id) => id !== adminId)
        : [...prevSelected, adminId]
    );
  };

  const handleSingleAction = (adminId, action) => {
    const actionConfirmed =
      action === "delete"
        ? window.confirm("Are you sure you want to delete this admin?")
        : true;

    if (actionConfirmed) {
      const actionThunk =
        action === "delete" ? deleteAdminThunk : toggleBlockAdminThunk;

      const actionPayload =
        action === "delete"
          ? [adminId]
          : { adminId, block: action === "block" };

      dispatch(actionThunk(actionPayload)).then(() => {
        dispatch(fetchAdminsThunk(adminFilterStatus[currentFilter]));
      });
    }
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    const confirmed = window.confirm(
      "Are you sure you want to close the modal?"
    );
    if (confirmed) {
      setIsCreateModalOpen(false);
    }
  };

  const openResetPasswordModal = (admin) => {
    setAdminForReset(admin);
    setIsResetPasswordModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    const confirmed = window.confirm(
      "Are you sure you want to close the modal?"
    );
    if (confirmed) {
      setIsResetPasswordModalOpen(false);
    }
  };

  const handleCreateAdmin = (adminData) => {
    dispatch(createAdminThunk(adminData)).then((response) => {
      setCreatedAdmin(adminData);
      setIsResetPasswordModalOpen(false);
      dispatch(fetchAdminsThunk(adminFilterStatus[currentFilter]));
    });
  };

  const handleResetPassword = (data) => {
    dispatch(updateAdminPasswordThunk(data)).then(() => {
      setIsResetPasswordModalOpen(false);
      dispatch(fetchAdminsThunk(adminFilterStatus[currentFilter]));
    });
  };

  const renderCreateModal = useMemo(() => {
    return (
      <Modal
        isOpen={isCreateModalOpen}
        closeModal={closeCreateModal}
        title="Create Admin"
        className="p-8"
      >
        <AdminCreateModal
          closeModal={closeCreateModal}
          onSubmit={handleCreateAdmin}
        />
      </Modal>
    );
  }, [isCreateModalOpen, createdAdmin]);

  const renderResetPasswordModal = useMemo(() => {
    return (
      <Modal
        isOpen={isResetPasswordModalOpen}
        closeModal={closeResetPasswordModal}
        title="Reset Password"
      >
        {adminForReset && (
          <AdminResetPasswordModal
            closeModal={closeResetPasswordModal}
            onSubmit={handleResetPassword}
            admin={adminForReset}
          />
        )}
      </Modal>
    );
  }, [isResetPasswordModalOpen, adminForReset]);

  return (
    <>
      {!isRootAdmin ? (
        <p>
          Only root admin can access this. If you have any queries, contact the
          root admin.
        </p>
      ) : (
        <div className="container mx-auto p-4 bg-background text-text">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Admin Management</h1>
          </div>
          <div className="sticky top-0 z-10 bg-background py-2 mb-4">
            <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
              <Filter
                defaultOption="All"
                options={["All", "ForgotPasswordRequests", "Blocked"]}
                onSelect={handleFilterChange}
              />
              <div className="flex gap-2 items-center justify-center mt-4 lg:mt-0">
                <Button variant="primary" onClick={openCreateModal}>
                  Create Admin
                </Button>
              </div>
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {admins.map((admin) => (
                <AdminCard
                  key={admin._id}
                  admin={admin}
                  handleSelectAdmin={handleSelectAdmin}
                  handleSingleAction={handleSingleAction}
                  openResetPasswordModal={() => openResetPasswordModal(admin)}
                  selected={selectedAdmins.includes(admin._id)}
                  currentFilter={currentFilter}
                />
              ))}
            </div>
          )}
          {!loading && admins.length <= 0 && (
            <p>No admins found for {currentFilter}</p>
          )}
        </div>
      )}
      {renderCreateModal}
      {renderResetPasswordModal}
    </>
  );
};

export default AdminManagement;
