import React, { useState, useEffect } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import classNames from "classnames";

const Filter = ({ options, maxTabs, onSelect, defaultOption }) => {
  const [selected, setSelected] = useState(defaultOption || options[0]);
  const [isDropdown, setIsDropdown] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDropdown(window.innerWidth < 640);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  maxTabs = maxTabs || options.length;

  const visibleOptions = options.slice(0, maxTabs);
  const dropdownOptions = options.slice(maxTabs);

  const handleSelection = (option) => {
    setSelected(option);
    onSelect(option);
  };

  return (
    <div className="relative inline-block text-left">
      {isDropdown ? (
        <Menu>
          <MenuButton className="inline-flex justify-center w-full rounded-md border border-neutral-dark shadow-sm px-4 py-2 bg-background text-sm font-medium text-text hover:bg-neutral-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            {selected}
            <FiChevronDown className="w-5 h-5 ml-2" />
          </MenuButton>
          <MenuItems className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-background ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options.map((option, index) => (
              <MenuItem key={index}>
                {({ active }) => (
                  <button
                    onClick={() => handleSelection(option)}
                    className={classNames(
                      selected === option
                        ? "bg-primary text-text-on-primary"
                        : "",
                      active ? "bg-neutral-light" : "",
                      "group flex rounded-md items-center w-full px-2 py-2 text-sm text-text"
                    )}
                  >
                    {option}
                  </button>
                )}
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>
      ) : (
        <div className="flex space-x-2">
          {visibleOptions.map((option, index) => (
            <button
              key={index}
              onClick={() => handleSelection(option)}
              className={classNames(
                selected === option
                  ? "bg-primary text-text-on-primary"
                  : "bg-neutral-light text-text",
                "px-4 py-2 rounded-md text-sm font-medium hover:border-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              )}
            >
              {option}
            </button>
          ))}
          {dropdownOptions.length > 0 && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton
                  className={classNames(
                    "inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-neutral-light text-sm font-medium text-text focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary",
                    dropdownOptions.includes(selected)
                      ? "border-0 bg-primary text-text-on-primary"
                      : "border-neutral-dark",
                    "hover:border-primary"
                  )}
                >
                  {dropdownOptions.includes(selected) ? selected : "More"}
                  <FiChevronDown className="w-5 h-5 ml-2" />
                </MenuButton>
              </div>
              <MenuItems className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-background ring-1 ring-black ring-opacity-5 focus:outline-none">
                {dropdownOptions.map((option, index) => (
                  <MenuItem key={index}>
                    {({ active }) => (
                      <button
                        onClick={() => handleSelection(option)}
                        className={classNames(
                          selected === option
                            ? "bg-primary text-text-on-primary"
                            : "",
                          active ? "bg-neutral-light" : "",
                          "group flex rounded-md items-center w-full px-2 py-2 text-sm text-text"
                        )}
                      >
                        {option}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          )}
        </div>
      )}
    </div>
  );
};

export default Filter;
