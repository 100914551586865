import axios from "axios";
import api from "./api";

const login = async (email, password) => {
  const response = await api.post("/admin/login", { email, password });
  return response.data;
};

const getMyDetails = async () => {
  const response = await api.get("/admin/me");
  return response.data;
};

const getAuthToken = async (refreshToken, config = {}) => {
  return axios.post(
    "http://localhost:3300/api/v1/admin/refresh-token",
    { refreshToken },
    config
  );
};

const requestPasswordReset = async (email) => {
  const response = await api.post("/admin/request-password-reset", { email });
  return response.data;
};

const authService = {
  login,
  getAuthToken,
  requestPasswordReset,
  getMyDetails,
};

export default authService;
