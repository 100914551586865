import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/common/Button";
import Input from "../../../components/common/Input";
import Modal from "../../../components/common/Modal";
import {
  requestPasswordReset,
  clearAuthError,
  clearPasswordResetMessage,
} from "../../../store/thunks/authThunks";

const ForgotPasswordModal = ({ isOpen, closeModal }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { passwordResetMessage, error: reduxError } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(clearAuthError());
      dispatch(clearPasswordResetMessage());
      setEmail("");
    }
  }, [isOpen, dispatch]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (error) {
      setError("");
    }
    if (reduxError) {
      dispatch(clearAuthError());
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    dispatch(requestPasswordReset(email));
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="Forgot Password">
      {passwordResetMessage ? (
        <p className="mt-6">{passwordResetMessage}</p>
      ) : (
        <form onSubmit={handleSubmit} className="mt-6">
          <Input
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            error={error}
            required
          />
          <Button type="submit" variant="primary">
            Request Password Update
          </Button>
        </form>
      )}
      {reduxError && <p className="text-red-500 mt-2">{reduxError}</p>}
    </Modal>
  );
};

export default ForgotPasswordModal;
