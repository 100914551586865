import React from "react";

const Badge = ({ children, variant = "primary" }) => {
  const baseStyles =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";

  const variantStyles = {
    primary: "bg-primary text-text-on-primary",
    secondary: "bg-secondary text-text",
    success: "bg-success text-text-on-primary",
    danger: "bg-danger text-text-on-primary",
    warning: "bg-warning text-text-on-primary",
  };

  return (
    <span className={`${baseStyles} ${variantStyles[variant]}`}>
      {children}
    </span>
  );
};

export default Badge;
