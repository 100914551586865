import api from "./api";

const getAdmin = async (id) => {
  const response = await api.get(`/admin/${id}`);
  return response.data;
};

const createAdmin = async (adminData) => {
  const response = await api.post("/admin/create", adminData);
  return response.data;
};

const deleteAdmin = async (id) => {
  const response = await api.delete(`/admin/delete/${id}`);
  return response.data;
};

const updateAdminPassword = async (passwordData) => {
  const response = await api.post("/admin/update-password", passwordData);
  return response.data;
};

const resetPassword = async (passwordData) => {
  const response = await api.post("/admin/reset-password", passwordData);
  return response.data;
};

const fetchAdmins = async (filterParams) => {
  const response = await api.get("/admin", { params: filterParams });
  return response.data;
};

const toggleBlockAdmin = async (adminId, block) => {
  const response = await api.patch(`/admin/${adminId}/toggleBlock`, { block });
  return response.data;
};

const adminService = {
  createAdmin,
  deleteAdmin,
  updateAdminPassword,
  resetPassword,
  getAdmin,
  fetchAdmins,
  toggleBlockAdmin,
};

export default adminService;
