import React from "react";

const TextArea = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  className = "",
}) => {
  return (
    <div className={className}>
      <label className="block text-sm font-medium text-color-text">
        {label}
      </label>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="block w-full px-3 py-2 border border-neutral-dark rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
      ></textarea>
      <p className="text-sm text-danger" style={{ minHeight: "1.25rem" }}>
        {error || "\u00A0"}
      </p>
    </div>
  );
};

export default TextArea;
