import axios from "axios";
import { getStore } from "../store/configureStore";
import { logout } from "../store/thunks/authThunks";
import authService from "../services/authService";

const api = axios.create({
  baseURL: "https://api.sggold.co.in/api/v1",
  // baseURL: "http://localhost:3300/api/v1",
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (!error.response) {
      // Handle network errors
      console.error("Network error:", error.message);
      return Promise.reject({
        ...error,
        message:
          "Network error: Please check your internet connection or server status.",
      });
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await authService.getAuthToken(refreshToken);
        const { authToken, refreshToken: newRefreshToken } = response.data;

        localStorage.setItem("token", authToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
        processQueue(null, authToken);
        return api(originalRequest);
      } catch (err) {
        processQueue(err, null);
        const store = getStore();
        store.dispatch(logout());
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
