import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "../../services/categoryService";

export const createCategoryThunk = createAsyncThunk(
  "category/createCategory",
  async (categoryData, { rejectWithValue }) => {
    try {
      const response = await categoryService.createCategory(categoryData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const getAllCategoriesThunk = createAsyncThunk(
  "category/getAllCategories",
  async (filters, { rejectWithValue }) => {
    try {
      const response = await categoryService.getAllCategories(filters);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const getCategoryByIdThunk = createAsyncThunk(
  "category/getCategoryById",
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await categoryService.getCategoryById(categoryId);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateCategoryByIdThunk = createAsyncThunk(
  "category/updateCategoryById",
  async ({ categoryId, categoryData }, { rejectWithValue }) => {
    try {
      const response = await categoryService.updateCategoryById(
        categoryId,
        categoryData
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteCategoryByIdThunk = createAsyncThunk(
  "category/deleteCategoryById",
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await categoryService.deleteCategoryById(categoryId);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const clearCategoryError = createAction("category/clearCategoryError");
