import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import adminProductService from "../../services/adminProductService";

export const createProductThunk = createAsyncThunk(
  "adminProduct/createProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await adminProductService.createProduct(productData);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateProductByIdThunk = createAsyncThunk(
  "adminProduct/updateProductById",
  async ({ id, productData }, { rejectWithValue }) => {
    try {
      const response = await adminProductService.updateProductById(
        id,
        productData
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateProductStatusByIdThunk = createAsyncThunk(
  "adminProduct/updateProductStatusById",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await adminProductService.updateProductStatusById(
        id,
        status
      );
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteProductByIdThunk = createAsyncThunk(
  "adminProduct/deleteProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminProductService.deleteProductById(id);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const getProductByIdThunk = createAsyncThunk(
  "adminProduct/getProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminProductService.getProductById(id);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const getAllProductsThunk = createAsyncThunk(
  "adminProduct/getAllProducts",
  async (filters, { rejectWithValue }) => {
    try {
      const response = await adminProductService.getAllProducts(filters);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const clearAdminProductError = createAction(
  "adminProduct/clearAdminProductError"
);
