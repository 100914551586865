import React from "react";
import Modal from "../../../components/common/Modal";
import InfoSection from "../../../components/UI/InfoSection";
import PreviewFile from "../../../components/common/PreviewFile";

const ProductModal = ({ isOpen, closeModal, selectedCartItem }) => {
  const productInfo = [
    {
      label: "Product Name",
      value: selectedCartItem.product?.name || undefined,
    },
    { label: "Type", value: selectedCartItem.product?.type },
    { label: "Category", value: selectedCartItem.product?.category },
    {
      label: "Gross Weight",
      value: selectedCartItem.grossWeight,
    },
    {
      label: "Net Weight",
      value: selectedCartItem.netWeight,
    },
    {
      label: "Description",
      value: selectedCartItem.product.description || undefined,
    },
    ...(selectedCartItem.product?.isStone
      ? [
          {
            label: "Stone Weight",
            value: selectedCartItem.stoneWeight,
          },
          {
            label: "Stone Charges",
            value: selectedCartItem.stoneCharges,
          },
        ]
      : []),
  ];

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="Product Details"
      isFullScreen
    >
      <div className="p-4">  
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="w-full lg:w-3/1 mb-4 lg:mb-0">
            <InfoSection
              info={productInfo}
              itemClassName="mt-2 text-lg"
              valueClassName="font-bold"
            />
          </div>
          <div className="w-full lg:w-3/4">
            <h4 className="text-md font-semibold mb-2">Media:</h4>
            <div className="flex space-x-2 overflow-x-auto scrollbar-thin">
              {selectedCartItem.product?.media?.map((media, index) => (
                <div key={index} className="flex-shrink-0 w-64 p-2">
                  <PreviewFile
                    preview={{ ...media, url: media.url }}
                    index={index}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
