import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategoryThunk,
  getAllCategoriesThunk,
  updateCategoryByIdThunk,
  deleteCategoryByIdThunk,
  clearCategoryError,
} from "../../store/thunks/categoryThunks";
import Button from "../../components/common/Button";
import { notifyError } from "../../utils/notification";
import CategoryCard from "./components/CategoryCard";
import Modal from "../../components/common/Modal";
import CategoryForm from "./components/CategoryForm";

const CategoryPage = () => {
  const dispatch = useDispatch();
  const { categories, loading, error } = useSelector((state) => state.category);
  const [showModal, setShowModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearCategoryError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(getAllCategoriesThunk());
  }, [dispatch]);

  const handleSingleAction = useCallback(
    (categoryId, action) => {
      if (action === "delete") {
        if (window.confirm("Are you sure you want to delete this category?")) {
          dispatch(deleteCategoryByIdThunk(categoryId)).then(() => {
            dispatch(getAllCategoriesThunk());
          });
        }
      } else if (action === "edit") {
        const category = categories.find((cat) => cat._id === categoryId);
        setCurrentCategory(category);
        setShowModal(true);
      }
    },
    [dispatch, categories]
  );

  const handleCreateCategory = useCallback(() => {
    setCurrentCategory(null);
    setShowModal(true);
  }, []);

  const filterEmptyFields = (data) => {
    return Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== undefined && value !== ""
      )
    );
  };

  const handleFormSubmit = useCallback(
    (categoryData) => {
      const filteredData = filterEmptyFields(categoryData);
      const thunk = currentCategory
        ? updateCategoryByIdThunk({
            categoryId: currentCategory._id,
            categoryData: filteredData,
          })
        : createCategoryThunk(filteredData);

      dispatch(thunk).then((response) => {
        if (!response.error) {
          dispatch(getAllCategoriesThunk());
          setShowModal(false);
        }
      });
    },
    [currentCategory, dispatch]
  );

  return (
    <div className="container mx-auto p-4 bg-background text-text">
      <h1 className="text-2xl font-bold mb-4">Categories</h1>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2 items-center" />
        <div className="flex gap-2 items-center">
          <Button variant="primary" onClick={handleCreateCategory}>
            Create Category
          </Button>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {categories.map((category) => (
            <CategoryCard
              key={category._id}
              category={category}
              handleSingleAction={handleSingleAction}
            />
          ))}
        </div>
      )}
      {!loading && categories.length <= 0 && <p>No Categories Found</p>}
      {showModal && (
        <Modal
          isOpen={true}
          closeModal={() => setShowModal(false)}
          title={currentCategory ? "Edit Category" : "Create Category"}
        >
          <CategoryForm
            category={currentCategory}
            onSubmit={handleFormSubmit}
            onCancel={() => setShowModal(false)}
            serverError={error}
          />
        </Modal>
      )}
    </div>
  );
};

export default CategoryPage;
