import { createSlice } from "@reduxjs/toolkit";
import {
  createAdminThunk,
  deleteAdminThunk,
  updateAdminPasswordThunk,
  resetPasswordThunk,
  getAdminThunk,
  fetchAdminsThunk,
  toggleBlockAdminThunk, // Import the new thunk
  clearAdminError,
} from "../thunks/adminThunks";

const initialState = {
  admins: [],
  forgotPasswordRequests: [],
  loading: false,
  error: null,
  selectedAdmin: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearAdminError, (state) => {
        state.error = null;
      })
      .addCase(getAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedAdmin = action.payload;
      })
      .addCase(getAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.admins.push(action.payload);
      })
      .addCase(createAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = state.admins.filter(
          (admin) => admin._id !== action.payload._id
        );
      })
      .addCase(deleteAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAdminPasswordThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdminPasswordThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = state.admins.map((admin) =>
          admin._id === action.payload._id ? action.payload : admin
        );
      })
      .addCase(updateAdminPasswordThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(resetPasswordThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAdminsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdminsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload;
      })
      .addCase(fetchAdminsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(toggleBlockAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleBlockAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = state.admins.map((admin) =>
          admin._id === action.payload.admin._id ? action.payload.admin : admin
        );
      })
      .addCase(toggleBlockAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default adminSlice.reducer;
