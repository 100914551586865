import { createSlice } from "@reduxjs/toolkit";
import {
  getAllOrdersForAdminThunk,
  updateMultipleOrderStatusByAdminThunk,
  deleteMultipleOrdersByAdminThunk,
  clearOrderError,
} from "../thunks/orderThunks";

const initialState = {
  orders: [],
  loading: false,
  error: null,
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clearOrderError, (state) => {
        state.error = null;
      })
      .addCase(getAllOrdersForAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllOrdersForAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(getAllOrdersForAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMultipleOrderStatusByAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMultipleOrderStatusByAdminThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(
        updateMultipleOrderStatusByAdminThunk.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteMultipleOrdersByAdminThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMultipleOrdersByAdminThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteMultipleOrdersByAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default orderSlice.reducer;
