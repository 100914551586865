import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordThunk,
  clearAdminError,
} from "../../store/thunks/adminThunks";
import Modal from "../common/Modal";
import Input from "../common/Input";
import Button from "../common/Button";

const UpdatePasswordModal = ({ isOpen, closeModal }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { loading, error: reduxError } = useSelector((state) => state.admin);

  useEffect(() => {
    if (isOpen) {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setErrors({});
    }
    dispatch(clearAdminError());
  }, [isOpen]);

  const validate = () => {
    const errors = {};
    if (!currentPassword)
      errors.currentPassword = "Current password is required.";
    if (!newPassword) errors.newPassword = "New password is required.";
    if (!confirmPassword)
      errors.confirmPassword = "Confirm password is required.";
    if (newPassword !== confirmPassword)
      errors.confirmPassword = "Passwords do not match.";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const passwordData = { currentPassword, newPassword };
      const resultAction = await dispatch(resetPasswordThunk(passwordData));

      if (resetPasswordThunk.fulfilled.match(resultAction)) {
        closeModal();
      } else {
        setErrors({
          general: resultAction.payload || "Failed to reset password",
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} title="Update Password">
      <form onSubmit={handleSubmit} className="mt-6 space-y-4">
        {(errors.general || reduxError) && (
          <p className="text-sm text-danger">{errors.general || reduxError}</p>
        )}
        <Input
          label="Current Password"
          name="currentPassword"
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          error={errors.currentPassword}
        />
        <Input
          label="New Password"
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          error={errors.newPassword}
        />
        <Input
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={errors.confirmPassword}
        />
        <div className="flex justify-end">
          <Button type="submit" variant="secondary" disabled={loading}>
            {loading ? "Updating..." : "Update Password"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdatePasswordModal;
