import React from "react";
import Button from "../../../components/common/Button";
import ListCard from "../../../components/UI/ListCard";
import InfoSection from "../../../components/UI/InfoSection";

const AdminCard = ({
  admin,
  handleSingleAction,
  openResetPasswordModal,
  selected,
  currentFilter,
}) => {
  const renderActionButtons = () => {
    if (currentFilter === "ForgotPasswordRequests") {
      return (
        <Button
          variant="primary"
          onClick={() => openResetPasswordModal(admin)}
          size="sm"
        >
          Reset Password
        </Button>
      );
    } else if (currentFilter === "Blocked") {
      return (
        <Button
          variant="success"
          onClick={() => handleSingleAction(admin._id, "unblock")}
          size="sm"
        >
          Unblock
        </Button>
      );
    } else {
      const buttonsConfig = [
        { variant: "danger", action: "block", label: "Block" },
        { variant: "danger-text", action: "delete", label: "Delete" },
      ];

      return buttonsConfig.map(({ variant, action, label }) => (
        <Button
          key={action}
          variant={variant}
          onClick={() => handleSingleAction(admin._id, action)}
          size="sm"
        >
          {label}
        </Button>
      ));
    }
  };

  const adminInfo = [
    { label: "Name", value: admin.name },
    { label: "Phone", value: admin.phoneNumber || undefined },
    { label: "Role", value: admin.role },
    { label: "Blocked", value: admin.isBlocked ? "Yes" : "No" },
  ];

  return (
    <ListCard selected={selected}>
      <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
        <div className="mb-2 lg:mb-0">
          <h2 className="text-xl font-semibold">
            <span>Admin Email:</span>{" "}
            <span className="text-primary-light">{admin.email}</span>
          </h2>
        </div>
        <div className="flex space-x-2 items-center justify-center">
          {renderActionButtons()}
        </div>
      </div>
      <div className="border-t pt-2">
        <InfoSection info={adminInfo} />
      </div>
    </ListCard>
  );
};

export default AdminCard;
