export const getCategoriesWithChild = (categories) => {
  if (!categories || categories.length === 0) return "No categories available";
  return categories
    .map(({ name, childCategories }) => {
      return [
        name,
        ...(Array.isArray(childCategories) ? childCategories : []),
      ].join(" >> ");
    })
    .join(" | ");
};
