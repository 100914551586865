import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrdersForAdminThunk,
  deleteMultipleOrdersByAdminThunk,
  updateMultipleOrderStatusByAdminThunk,
  clearOrderError,
} from "../../store/thunks/orderThunks";
import Button from "../../components/common/Button";
import Filter from "../../components/common/Filter";
import Checkbox from "../../components/common/Checkbox";
import { notifyError } from "../../utils/notification";
import OrderCard from "./components/OrderCard";

const orderFilterStatus = {
  Accepted: "AdminAccepted",
  Rejected: "AdminRejected",
  Pending: "Pending",
  Cancelled: "UserCancelled",
  Completed: "AdminCompleted",
  All: "",
};

const orderAction = {
  accept: "AdminAccepted",
  reject: "AdminRejected",
  complete: "AdminCompleted",
};

const buttonsConfig = {
  Pending: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
  ],
  Rejected: [{ variant: "success", action: "accept", label: "Accept" }],
  Cancelled: [{ variant: "danger-text", action: "delete", label: "Delete" }],
  Accepted: [
    { variant: "danger", action: "reject", label: "Reject" },
    { variant: "primary", action: "complete", label: "Complete" },
  ],
  Completed: [{ variant: "danger-text", action: "delete", label: "Delete" }],
  default: [
    { variant: "success", action: "accept", label: "Accept" },
    { variant: "danger", action: "reject", label: "Reject" },
    { variant: "danger-text", action: "delete", label: "Delete" },
  ],
};

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector((state) => state.orders);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("Pending");

  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearOrderError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(
      getAllOrdersForAdminThunk({ orderStatus: orderFilterStatus.Pending })
    );
  }, [dispatch]);

  useEffect(() => {
    if (selectedOrders.length === orders.length && orders.length > 0) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedOrders, orders]);

  const handleFilterChange = (status) => {
    setCurrentFilter(status);
    dispatch(
      getAllOrdersForAdminThunk({ orderStatus: orderFilterStatus[status] })
    );
    setSelectedOrders([]);
    setSelectAll(false);
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(orders.map((order) => order._id));
    }
    setSelectAll(!selectAll);
  };

  const handleBulkAction = (status) => {
    const actionConfirmed =
      status === "delete"
        ? window.confirm("Are you sure you want to delete these orders?")
        : true;

    if (actionConfirmed) {
      const actionThunk =
        status === "delete"
          ? deleteMultipleOrdersByAdminThunk
          : updateMultipleOrderStatusByAdminThunk;

      const actionPayload =
        status === "delete"
          ? selectedOrders
          : { orderIds: selectedOrders, orderStatus: orderAction[status] };

      dispatch(actionThunk(actionPayload)).then(() => {
        dispatch(
          getAllOrdersForAdminThunk({
            orderStatus: orderFilterStatus[currentFilter],
          })
        );
      });
    }
  };

  const handleSingleAction = (orderId, action) => {
    const actionConfirmed =
      action === "delete"
        ? window.confirm("Are you sure you want to delete this order?")
        : true;

    if (actionConfirmed) {
      const actionThunk =
        action === "delete"
          ? deleteMultipleOrdersByAdminThunk
          : updateMultipleOrderStatusByAdminThunk;

      const actionPayload =
        action === "delete"
          ? [orderId]
          : { orderIds: [orderId], orderStatus: orderAction[action] };

      dispatch(actionThunk(actionPayload)).then(() => {
        dispatch(
          getAllOrdersForAdminThunk({
            orderStatus: orderFilterStatus[currentFilter],
          })
        );
      });
    }
  };

  const renderBulkActionButtons = () => {
    return (buttonsConfig[currentFilter] || buttonsConfig.default).map(
      ({ variant, action, label }) => (
        <Button
          key={action}
          variant={variant}
          onClick={() => handleBulkAction(action)}
          disabled={!selectedOrders.length}
        >
          {label}
        </Button>
      )
    );
  };

  return (
    <div className="container mx-auto p-4 bg-background text-text">
      <h1 className="text-2xl font-bold mb-4">Orders</h1>
      <div className="sticky top-0 z-10 bg-background py-2 mb-4">
        <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
          <Filter
            defaultOption="Pending"
            options={[
              "All",
              "Pending",
              "Accepted",
              "Rejected",
              "Cancelled",
              "Completed",
            ]}
            onSelect={handleFilterChange}
          />
          <div className="flex gap-2 items-center justify-center mt-4 lg:mt-0">
            <div>{`Selected: ${selectedOrders.length}`}</div>
            <Checkbox
              label=""
              name="select-all"
              checked={selectAll}
              onChange={handleSelectAll}
              size="lg"
              className="mx-2 mr-4"
            />
            {renderBulkActionButtons()}
          </div>
        </div>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {orders.map((order) => (
            <OrderCard
              key={order._id}
              order={order}
              handleSelectOrder={handleSelectOrder}
              handleSingleAction={handleSingleAction}
              selected={selectedOrders.includes(order._id)}
              currentFilter={currentFilter}
            />
          ))}
        </div>
      )}
      {!loading && orders.length <= 0 && (
        <p> Not Order Found For {currentFilter} </p>
      )}
    </div>
  );
};

export default OrdersPage;
